import React, { Fragment, useEffect, useState } from "react";
import "./myAssessment.scss";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ProgressBar, Table } from "react-bootstrap";
import { Accordion, Card, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDetailedReport,
  getDetailedOldReport,
  resetReportValue,
  updatedScore,
} from "../../Redux/ActionCreators/reportAction";
import { useSelector } from "react-redux";
import {
  convertDate,
  formatAMPM,
  getMbtiResult,
} from "../../utils/miscelleneous";
import Lightbox from "../../utils/lightbox";
import UpArrow from "../../assets/up-accordion.svg";
import DownArrow from "../../assets/down-accordion.svg";
import { Dialog } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

const progressStyle = {
  // width: ${progress}%,
  width: "100%",
  backgroundColor: "Green",
  height: "10px",
  borderRadius: "5px",
  BorderColor: "",
  backgroundImage: "linear-gradient(to right, #5edc82, #14b4c7)",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
  MarginOutlined: "Bold",
};

export default function TestReport() {
  const [activeKey, setActiveKey] = useState(null);
  const [active, setActive] = useState(0);
  const [openBox, setOpenBox] = useState(false);
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const [score, setScore] = useState("");
  const { detailReport } = useSelector((state) => state.myAssessment);

  useEffect(() => {
    const firstRender = async () => {
      await setLoading(true);
      if (location.state.type === "new") {
        await dispatch(getDetailedReport(location.state.id));
      } else {
        await dispatch(getDetailedOldReport(location.state.id));
      }
      await setLoading(false);
    };

    firstRender();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleAccordionClick = (index) => {
    if (activeKey === index) {
      setActiveKey(null); // Close if clicking on the already open accordion
    } else {
      setActiveKey(index); // Open the clicked accordion
    }
  };

  const accordionIcon = (index) => {
    if (activeKey === index) {
      return <img src={UpArrow} style={{ float: "right" }} alt="" />;
    } else {
      return <img src={DownArrow} alt="" style={{ float: "right" }} />;
    }
  };

  const updateScore = async (el) => {
    setLoadingReport(true);
    let obj = {
      candidateTestId: el.candidateTestId,
      candidateTestQuestionId: el.candidateTestQuestionId,
      score: score,
    };
    await dispatch(updatedScore(obj));
    await dispatch(getDetailedReport(location.state.id));
    setLoadingReport(false);
  };

  const excludedTestNames = [
    "Gappeo 4D Personality Test",
    "Gappeo 6-Facet Personality Test",
  ];
  const testNames = detailReport?.tests?.map((test) => test.testName) || [];

  const hasOnlyExcludedTestNames = testNames.every((name) =>
    excludedTestNames.includes(name)
  );
  const hasMixedTestNames = testNames.some(
    (name) => !excludedTestNames.includes(name)
  );

  return (
    <div className="test-report-holder">
      <Dialog
        className="screen-loader"
        open={loading}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {t("Loading", { ns: "mytest" })}...
      </Dialog>
      <Dialog
        className="screen-loader"
        open={loadingReport}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {t("Loading Score", { ns: "mytest" })}...
      </Dialog>
      {loading ? (
        ""
      ) : location.state.type === "new" ? (
        detailReport !== null ? (
          <Fragment>
            <div className="test-report-header">
              <div
                className="d-flex justify-content-between"
                style={{ width: "55%" }}
              >
                <img
                  src="/images/back-white.svg"
                  className="cursor-pointer"
                  onClick={() => goBack()}
                  alt=""
                />
                <h3>{t("Test Report", { ns: "mytest" })}</h3>
              </div>
              <hr style={{ color: "#fff", border: "1px solid #3D4143" }} />
              <div className="row justify-content-between">
                <div className="col-md-3 col-12">
                  <div className="d-flex align-items-center">
                    {/* <div className='profile-img'>
                                <img src='/images/user.svg' alt='' />
                            </div>&nbsp;&nbsp; */}
                    <div>
                      <h4>{detailReport?.name}</h4>
                      <h6 className="mt-1">{detailReport?.emailId}</h6>
                      {/* <h6 className='mt-1'>121212121</h6> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-12">
                  <div className={window.innerWidth > 767 ? "row" : "row mt-3"}>
                    <div className="col-md-3 col-3">
                      <h6>{t("Test Name", { ns: "mytest" })}</h6>
                      <p>{detailReport?.testName}</p>
                    </div>
                    <div className="col-md-2 col-2">
                      <h6>{t("Test Id", { ns: "mytest" })}</h6>
                      <p>{detailReport?.testId}</p>
                    </div>
                    <div className="col-md-2 col-2">
                      <h6>{t("Test Date", { ns: "mytest" })}</h6>
                      <p>{convertDate(detailReport?.testAttemptDate)}</p>
                    </div>
                    <div className="col-md-2 col-2">
                      <h6>{t("Test Time", { ns: "mytest" })}</h6>
                      <p>{formatAMPM(detailReport?.testAttemptDate)}</p>
                    </div>
                    <div className="col-md-2 col-2">
                      <h6>{t("Test Duration", { ns: "mytest" })}</h6>
                      <p>
                        {detailReport?.candidateTestDuration}{" "}
                        {t("Minutes", { ns: "mytest" })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="test-score">
              <h4 style={{ width: "50%" }}>
                {t("Test Score Summary", { ns: "mytest" })}
              </h4>
              <div
                style={{ width: "40%" }}
                className="d-flex justify-content-between align-items-center"
              >
                {(hasMixedTestNames || !hasOnlyExcludedTestNames) ? (
                  <div>
                    <h2>{detailReport?.score}%</h2>
                    <p>{t("Overall Score", { ns: "mytest" })}</p>
                  </div>
                ) : null}
                {(hasMixedTestNames || !hasOnlyExcludedTestNames) ? (
                  <div>
                    <h2>{detailReport?.topicAverage}%</h2>
                    <p>{t("Topic Average", { ns: "mytest" })}</p>
                  </div>
                ) : null}
                {/* <div>
                        <h2 style={{ fontSize: 18 }}>Intermediate</h2>
                        <p>Proficiency level</p>
                    </div> */}
              </div>
            </div>
            <div className="individual-report">
              <h4>{t("Individual Test Scores", { ns: "mytest" })}</h4>
              <p className="mt-2">
                {t("Select test to view topic level scores", { ns: "mytest" })}
              </p>

              <div className="test-tabs">
                {detailReport?.tests &&
                  detailReport?.tests?.map((el, i) => {
                    return (
                      <Fragment>
                        <div
                          className={
                            i === active ? "test-tab active" : "test-tab"
                          }
                          onClick={() => setActive(i)}
                        >
                          <div className="progress-circular">
                            <CircularProgressbarWithChildren
                              value={
                                (el.referenceTypeId === 1 &&
                                  el.testType === 5) ||
                                el.referenceTypeId === 4 ||
                                el.referenceTypeId === 2 ||
                                el.referenceTypeId === 5
                                  ? 100
                                  : el.score
                              }
                              maxValue={100}
                              minValue="0"
                              styles={{
                                root: {
                                  width: 80,
                                  height: 80,
                                  fontSize: 10,
                                  borderRadius: 150,
                                  marginTop: 0,
                                  background: "#fff",
                                  verticalAlign: "middle",
                                  position: "relative",
                                },
                                path: {
                                  strokeLinecap: "square",
                                  stroke: "#02C573",
                                  strokeWidth: 8,
                                },
                                trail: {
                                  // Trail color
                                  stroke: "#F2f2f3",
                                  strokeWidth: 8,
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "round",
                                },
                              }}
                            >
                              <div
                                className="text-center"
                                style={{
                                  fontSize: 14,
                                  color: "#3d4143",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {(el.referenceTypeId === 1 &&
                                  el.testType === 5) ||
                                el.referenceTypeId === 2 ||
                                el.referenceTypeId === 4 ||
                                el.referenceTypeId === 5 ? (
                                  <strong
                                    style={{ fontSize: 10 }}
                                    className="text-center"
                                  >
                                    {"Completed"}
                                  </strong>
                                ) : (
                                  <strong className="text-center">
                                    {`${el.score}%`}
                                  </strong>
                                )}
                              </div>
                            </CircularProgressbarWithChildren>
                          </div>
                          <h5>
                            {el.testName} | {el.languageCode}
                          </h5>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              <div className="d-flex flex-wrap mt-2" style={{ minHeight: 280 }}>
                <div
                  className="test-topic mt-3 ms-2"
                  style={{
                    width:
                      window.innerWidth > 767
                        ? detailReport?.tests[active]?.referenceTypeId === 3 ||
                          detailReport?.tests[active]?.referenceTypeId === 4 ||
                          !detailReport?.tests[active]?.topics?.length
                          ? "100%"
                          : "65%"
                        : "100%",
                  }}
                >
                  {detailReport?.tests &&
                  detailReport?.tests[active]?.referenceTypeId === 1 ? (
                    <Table responsiopenOneReportve bordered={false}>
                      {!detailReport?.tests[active]?.topics
                        ?.length ? null : detailReport?.tests[active]
                          ?.testType === 5 ? (
                        <thead>
                          <th>{t("Question", { ns: "mytest" })}</th>
                          <th>{t("Correct", { ns: "mytest" })}</th>
                          {/* <th>Difficulty level scores</th> */}
                        </thead>
                      ) : (
                        <thead>
                          <th>{t("Topic level scores", { ns: "mytest" })}</th>
                          <th>
                            {t("Topic Average", { ns: "mytest" })}-
                            {detailReport?.tests[active]?.score}%
                          </th>
                          {/* <th>Difficulty level scores</th> */}
                        </thead>
                      )}
                      <tbody style={{ verticalAlign: "baseline" }}>
                        {!detailReport?.tests[active]?.topics?.length ? (
                          <div>
                            <Accordion activeKey={activeKey}>
                              {detailReport?.tests[active]?.questions
                                ?.map(
                                  (el) =>
                                    [
                                      {
                                        ...el,
                                        correct:
                                          el.isAnswered === 0 ? false : true,
                                        correct_id: el.questionOptions.filter(
                                          (id) => id.iscorrect
                                        )[0]?.questionOptionID,
                                      },
                                    ][0]
                                )
                                .map((el, i) => {
                                  return (
                                    <Card>
                                      <Card.Header>
                                        <div
                                          className="d-flex align-items-center justify-content-between"
                                          as={Button}
                                          variant="link"
                                          eventKey={i}
                                          onClick={() =>
                                            handleAccordionClick(i)
                                          }
                                        >
                                          <span
                                            style={{ width: "70%" }}
                                            dangerouslySetInnerHTML={{
                                              __html: el.question,
                                            }}
                                          />{" "}
                                          <p
                                            style={{
                                              width: "15%",
                                              fontSize: 16,
                                              color: "#000000",
                                            }}
                                          >
                                            Type:{" "}
                                            {el.questionTypeId === 1
                                              ? "MCQ"
                                              : "Essay"}
                                          </p>
                                          &nbsp;&nbsp;{" "}
                                          <p
                                            style={{
                                              width: "15%",
                                              fontSize: 16,
                                              color: "#000000",
                                            }}
                                          >
                                            Score :{" "}
                                            {el.reviewStatus === 2 ? (
                                              <span style={{ color: "red" }}>
                                                Pending
                                              </span>
                                            ) : (
                                              `${el.answerScore} / ${el.score}`
                                            )}
                                          </p>{" "}
                                          {accordionIcon(i)}
                                        </div>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={i}>
                                        <Card.Body>
                                          <div
                                            className=" mt-2 px-2"
                                            dangerouslySetInnerHTML={{
                                              __html: el.description,
                                            }}
                                          />

                                          {el.questionTypeId === 1 ? (
                                            <ul className="ques-options">
                                              {el?.questionOptions?.map(
                                                (option, i) => {
                                                  console.log(el);
                                                  return (
                                                    <li
                                                      className={
                                                        el.correct_id ===
                                                          option.questionOptionID &&
                                                        el.correct
                                                          ? "correct"
                                                          : el.correct ===
                                                              false &&
                                                            el.selectedOptionId ===
                                                              option.questionOptionID
                                                          ? "incorrect"
                                                          : el.correct_id ===
                                                            option.questionOptionID
                                                          ? "correct"
                                                          : null
                                                      }
                                                    >
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            option.questionOption,
                                                        }}
                                                      />
                                                      {el.correct_id ===
                                                        el.selectedOptionId &&
                                                      el.correct ? (
                                                        <img
                                                          src="/images/correct.svg"
                                                          alt=""
                                                        />
                                                      ) : !el.correct &&
                                                        option.questionOptionID ===
                                                          el.selectedOptionId ? (
                                                        <img
                                                          src="/images/incorrect.svg"
                                                          alt=""
                                                        />
                                                      ) : el.correct_id ===
                                                        option.questionOptionID ? (
                                                        <img
                                                          src="/images/correct.svg"
                                                          alt=""
                                                        />
                                                      ) : null}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          ) : (
                                            <div>
                                              <textarea
                                                className="form-control"
                                                value={el.answerText}
                                                disabled
                                              />
                                            </div>
                                          )}
                                          {el.reviewStatus === 2 ? (
                                            <Fragment>
                                              <div
                                                className="mt-3"
                                                style={{ width: "40%" }}
                                              >
                                                <select
                                                  className="form-control"
                                                  value={score}
                                                  onChange={(e) =>
                                                    setScore(e.target.value)
                                                  }
                                                >
                                                  <option hidden>
                                                    {t("Select score", {
                                                      ns: "mytest",
                                                    })}
                                                  </option>
                                                  {Array.from(
                                                    { length: el.score + 1 },
                                                    (_, i) => i
                                                  )?.map((el) => {
                                                    return (
                                                      <option>{el}</option>
                                                    );
                                                  })}
                                                </select>
                                              </div>
                                              <button
                                                className="submit-btn mt-3"
                                                disabled={!score}
                                                onClick={() => updateScore(el)}
                                              >
                                                {t("Submit", { ns: "mytest" })}
                                              </button>
                                            </Fragment>
                                          ) : null}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })}
                            </Accordion>
                          </div>
                        ) : detailReport?.tests[active]?.testType === 5 ? (
                          detailReport?.tests[active].questions?.map(
                            (el1, i) => {
                              return (
                                <tr>
                                  <td
                                    style={{ fontSize: 16 }}
                                    dangerouslySetInnerHTML={{
                                      __html: el1?.question,
                                    }}
                                  ></td>
                                  <td>
                                    {el1.isAnswered === 0 ? (
                                      <img
                                        src="/images/close-check-red.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="/images/green-check.svg"
                                        alt=""
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          detailReport?.tests[active]?.topics?.map((el1) => {
                            return (
                              <tr>
                                <td>{el1.skillTopicName}</td>
                                <td>
                                  <div className="d-flex">
                                    <ProgressBar
                                      style={{ width: "50%" }}
                                      variant="succ"
                                      now={el1.score}
                                    />{" "}
                                    &nbsp;
                                    <p>{el1.score}%</p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </Table>
                  ) : detailReport?.tests[active]?.referenceTypeId === 3 ? (
                    <div>
                      <Accordion activeKey={activeKey}>
                        {detailReport?.tests[active]?.codingQuestions.map(
                          (el, i) => {
                            return (
                              <Card>
                                <Card.Header>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    as={Button}
                                    variant="link"
                                    eventKey={i}
                                    onClick={() => handleAccordionClick(i)}
                                  >
                                    <span style={{ width: "80%" }}>
                                      {el.question}
                                    </span>{" "}
                                    <p
                                      style={{ fontSize: 16, color: "#000000" }}
                                    >
                                      Score : {el.candidateScore} / {el.score}
                                    </p>{" "}
                                    {accordionIcon(i)}
                                  </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={i}>
                                  <Card.Body>
                                    <p className="ques-describe">
                                      {t("Problem Statement", { ns: "mytest" })}
                                    </p>
                                    <div
                                      className=" mt-2 px-2"
                                      dangerouslySetInnerHTML={{
                                        __html: el.description,
                                      }}
                                    />
                                    <div className="mt-3">
                                      <p className="ques-describe">
                                        {t("Score", { ns: "mytest" })}
                                      </p>
                                      <h4 className="mt-2 px-2">
                                        {el.candidateScore} / {el.score}
                                      </h4>
                                    </div>
                                    {el.finalCodeSubmitted && (
                                      <div className="mt-3 ">
                                        <p className="ques-describe">
                                          {t("Final Code Submited", {
                                            ns: "mytest",
                                          })}
                                        </p>
                                        <div
                                          className=" mt-2 px-2"
                                          dangerouslySetInnerHTML={{
                                            __html: el.finalCodeSubmitted,
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div className="mt-3">
                                      <p className="ques-describe">
                                        {t("Compilation Statistics", {
                                          ns: "mytest",
                                        })}
                                      </p>
                                      <div className="div-row1 px-2">
                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                          <div
                                            className="circle"
                                            style={{
                                              borderColor: "#1d8bcb",
                                              color: "#1d8bcb",
                                            }}
                                          >
                                            {el.totalAttempts}
                                          </div>
                                          <h6>
                                            {t("Total Attempts", {
                                              ns: "mytest",
                                            })}
                                          </h6>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                          <div
                                            className="circle"
                                            style={{
                                              borderColor: "green",
                                              color: "green",
                                            }}
                                          >
                                            {el.succesfulAttempts}
                                          </div>
                                          <h6>
                                            {t("Successful", { ns: "mytest" })}
                                          </h6>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                          <div
                                            className="circle"
                                            style={{
                                              borderColor: "red",
                                              color: "red",
                                            }}
                                          >
                                            {el.errors}
                                          </div>
                                          <h6>
                                            {t("Errors", { ns: "mytest" })}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {el.executions?.length ? (
                                      <div className="mt-3">
                                        <p className="ques-describe">
                                          {t("TestCases", { ns: "mytest" })}
                                        </p>
                                        <div className="px-2 mt-2">
                                          {el.executions?.map((el1, ind) => {
                                            return (
                                              <div className="col-md-12 d-flex align-items-center mb-3">
                                                <h6>
                                                  {t("TestCase", {
                                                    ns: "mytest",
                                                  })}{" "}
                                                  {ind + 1}
                                                </h6>
                                                <h6 className="ms-5">
                                                  {el1.statusId === 1 ? (
                                                    <img
                                                      src="/images/green-check.svg"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/close-check-red.svg"
                                                      alt=""
                                                    />
                                                  )}
                                                </h6>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : null}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            );
                          }
                        )}
                      </Accordion>
                    </div>
                  ) : detailReport?.tests[active]?.referenceTypeId === 4 ? (
                    <div>
                      <div className="mt-3">
                        {
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: "column",
                                height: 270,
                              },
                              legend: {
                                enabled: false,
                              },

                              title: {
                                text: "",
                              },

                              colors: ["#51488c"],
                              xAxis: {
                                categories: detailReport?.tests[
                                  active
                                ]?.personalityTopics?.map((el) => el.topic),
                                type: "Months",
                                title: {
                                  text: "Competencies",
                                },
                              },
                              yAxis: {
                                type: "Scores",
                                title: {
                                  text: "",
                                },
                              },
                              plotOptions: {
                                // column: {
                                //     borderRadiusTopLeft: 3,
                                //     borderRadiusTopRight: 3,
                                // },
                                series: {
                                  animation: false,
                                  pointWidth: 30,
                                  dataLabels: {
                                    enabled: true,
                                    crop: false,
                                    overflow: false,
                                    format: "{y} %",
                                  },
                                },
                              },
                              credits: {
                                enabled: false,
                              },
                              series: [
                                {
                                  enableMouseTracking: false,
                                  showInLegend: false,
                                  grouping: false,
                                  data: detailReport?.tests[
                                    active
                                  ]?.personalityTopics?.map(
                                    (el) => el.percentage
                                  ),
                                },
                              ],
                            }}
                          />
                        }
                      </div>
                      {detailReport?.tests[active]?.personalityTopics.map(
                        (personality) => (
                          <div
                            className="personality "
                            style={{ marginTop: "3%" }}
                          >
                            <div style={{ display: "flex" }}>
                              <h1 style={{ flex: "1" }}>{personality.topic}</h1>
                              <div style={{ flex: "1" }}>
                                <div style={{ width: "100%" }}>
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ position: "relative" }}
                                  >
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      width="35"
                                      height="35"
                                      viewBox="0 0 64 64"
                                      style={{
                                        position: "absolute",
                                        left: `${personality.percentage}%`,
                                        transform: "translate(-50%, -15%)",
                                      }}
                                    >
                                      <path
                                        d="M0 0 C4.94895464 3.00548104 8.39324937 7.12338505 10.875 12.3125 C13.7325779 26.60038948 6.58084435 38.14676314 -0.984375 49.734375 C-3.93702588 54.14312769 -7.08922388 58.37167416 -10.5 62.4375 C-13.73914606 61.06790784 -15.32321442 59.71043276 -17.26953125 56.796875 C-18.00949341 55.69496826 -18.00949341 55.69496826 -18.7644043 54.57080078 C-19.27527588 53.78431152 -19.78614746 52.99782227 -20.3125 52.1875 C-20.84077393 51.38425293 -21.36904785 50.58100586 -21.91333008 49.75341797 C-36.63372398 27.00228398 -36.63372398 27.00228398 -34.109375 13.3203125 C-32.32888342 7.45382101 -28.50330456 3.82801774 -23.5 0.4375 C-16.67636021 -2.99146472 -6.9834852 -3.14729604 0 0 Z "
                                        fill="#1581B4"
                                        transform="translate(43.5,1.5625)"
                                      />
                                    </svg>
                                    &nbsp;
                                    <div
                                      style={{
                                        fontSize: 12,
                                        color: "#fff",
                                        position: "absolute",
                                        left: `${personality.percentage}%`,
                                        transform: "translate(-45%, -40%)",
                                        zIndex: 1,
                                      }}
                                    >
                                      {`${Math.floor(personality.percentage)}`}
                                    </div>
                                  </div>
                                  <div style={progressStyle}></div>
                                  <div className="d-flex align-items-center">
                                    <div style={{ marginRight: "auto" }}>
                                      {personality.Minbar}
                                    </div>
                                    <div style={{ marginLeft: "auto" }}>
                                      {personality.Maxbar}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <p style={{ lineHeight: "2" }}>
                              {personality?.topicDescription}
                            </p>
                            {/* <br /> */}
                            <hr></hr>
                          </div>
                        )
                      )}
                    </div>
                  ) : detailReport?.tests[active]?.referenceTypeId === 5 ? (
                    <div>
                      {detailReport?.tests[active]?.mbtiTestResult !== null
                        ? getMbtiResult(
                            detailReport?.tests[active]?.mbtiTestResult
                          ).map((personality) => (
                            <div
                              className="personality "
                              style={{ marginTop: "3%" }}
                            >
                              <div style={{ display: "flex" }}>
                                <h1 style={{ flex: "1" }}>
                                  <p style={{ color: "#000" }}>Type</p>{" "}
                                  {personality?.type}
                                </h1>
                              </div>
                              <br />
                              <div style={{ display: "flex" }}>
                                <h1 style={{ flex: "1" }}>
                                  <p style={{ color: "#000" }}>Nick Name</p>{" "}
                                  {personality?.nickname}
                                </h1>
                              </div>
                              <br />
                              <p style={{ color: "#000" }}>
                                {t("Description", { ns: "mytest" })}
                              </p>
                              <p className="mt-1">{personality?.description}</p>
                              {/* <br /> */}
                              <hr></hr>
                            </div>
                          ))
                        : null}
                    </div>
                  ) : (detailReport?.tests[active]?.questions?.length ||
                      detailReport?.tests[active]?.topics?.length) > 0 ? (
                    <Table responsiopenOneReportve bordered={false}>
                      {detailReport?.tests[active]?.referenceTypeId ===
                      2 ? null : (
                        <thead>
                          <th>{t("Topic level scores", { ns: "mytest" })}</th>
                          <th>
                            {t("Topic Average", { ns: "mytest" })}-
                            {detailReport?.tests[active]?.score}%
                          </th>
                          {/* <th>Difficulty level scores</th> */}
                        </thead>
                      )}
                      <tbody style={{ verticalAlign: "baseline" }}>
                        {detailReport?.tests[active]?.referenceTypeId === 2 ? (
                          <div>
                            <Accordion activeKey={activeKey}>
                              {detailReport?.tests[active]?.questions
                                ?.map(
                                  (el) =>
                                    [
                                      {
                                        ...el,
                                        correct:
                                          el.isAnswered === 0 ? false : true,
                                        correct_id: el.questionOptions.filter(
                                          (id) => id.iscorrect
                                        )[0]?.questionOptionID,
                                      },
                                    ][0]
                                )
                                .map((el, i) => {
                                  return (
                                    <Card>
                                      <Card.Header>
                                        <div
                                          className="d-flex align-items-center justify-content-between"
                                          as={Button}
                                          variant="link"
                                          eventKey={i}
                                          onClick={() =>
                                            handleAccordionClick(i)
                                          }
                                        >
                                          <span
                                            style={{ width: "70%" }}
                                            dangerouslySetInnerHTML={{
                                              __html: el.question,
                                            }}
                                          />{" "}
                                          <p
                                            style={{
                                              width: "15%",
                                              fontSize: 16,
                                              color: "#000000",
                                            }}
                                          >
                                            Type:{" "}
                                            {el.questionTypeId === 1
                                              ? "MCQ"
                                              : "Essay"}
                                          </p>
                                          &nbsp;&nbsp;{" "}
                                          <p
                                            style={{
                                              width: "15%",
                                              fontSize: 16,
                                              color: "#000000",
                                            }}
                                          >
                                            Score :{" "}
                                            {el.reviewStatus === 2 ? (
                                              <span style={{ color: "red" }}>
                                                Pending
                                              </span>
                                            ) : (
                                              `${el.answerScore} / ${el.score}`
                                            )}
                                          </p>{" "}
                                          {accordionIcon(i)}
                                        </div>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={i}>
                                        <Card.Body>
                                          <div
                                            className=" mt-2 px-2"
                                            dangerouslySetInnerHTML={{
                                              __html: el.description,
                                            }}
                                          />

                                          {el.questionTypeId === 1 ? (
                                            <ul className="ques-options">
                                              {el?.questionOptions?.map(
                                                (option, i) => {
                                                  console.log(el);
                                                  return (
                                                    <li
                                                      className={
                                                        el.correct_id ===
                                                          option.questionOptionID &&
                                                        el.correct
                                                          ? "correct"
                                                          : el.correct ===
                                                              false &&
                                                            el.selectedOptionId ===
                                                              option.questionOptionID
                                                          ? "incorrect"
                                                          : el.correct_id ===
                                                            option.questionOptionID
                                                          ? "correct"
                                                          : null
                                                      }
                                                    >
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            option.questionOption,
                                                        }}
                                                      />
                                                      {el.correct_id ===
                                                        el.selectedOptionId &&
                                                      el.correct ? (
                                                        <img
                                                          src="/images/correct.svg"
                                                          alt=""
                                                        />
                                                      ) : !el.correct &&
                                                        option.questionOptionID ===
                                                          el.selectedOptionId ? (
                                                        <img
                                                          src="/images/incorrect.svg"
                                                          alt=""
                                                        />
                                                      ) : el.correct_id ===
                                                        option.questionOptionID ? (
                                                        <img
                                                          src="/images/correct.svg"
                                                          alt=""
                                                        />
                                                      ) : null}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          ) : (
                                            <div>
                                              <textarea
                                                className="form-control"
                                                value={el.answerText}
                                                disabled
                                              />
                                            </div>
                                          )}
                                          {el.reviewStatus === 2 ? (
                                            <Fragment>
                                              <div
                                                className="mt-3"
                                                style={{ width: "40%" }}
                                              >
                                                <select
                                                  className="form-control"
                                                  value={score}
                                                  onChange={(e) =>
                                                    setScore(e.target.value)
                                                  }
                                                >
                                                  <option hidden>
                                                    Select score
                                                  </option>
                                                  {Array.from(
                                                    { length: el.score + 1 },
                                                    (_, i) => i
                                                  )?.map((el) => {
                                                    return (
                                                      <option>{el}</option>
                                                    );
                                                  })}
                                                </select>
                                              </div>
                                              <button
                                                className="submit-btn mt-3"
                                                disabled={!score}
                                                onClick={() => updateScore(el)}
                                              >
                                                {t("Submit", { ns: "mytest" })}
                                              </button>
                                            </Fragment>
                                          ) : null}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })}
                            </Accordion>
                          </div>
                        ) : (
                          detailReport?.tests[active]?.topics?.map((el1) => {
                            return (
                              <tr>
                                <td>{el1.skillTopicName}</td>
                                <td>
                                  <div className="d-flex">
                                    <ProgressBar
                                      style={{ width: "50%" }}
                                      variant="succ"
                                      now={el1.score}
                                    />{" "}
                                    &nbsp;
                                    <p>{el1.score}%</p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    "No data available"
                  )}
                </div>
                {detailReport?.tests[active]?.testType === 5 ||
                detailReport?.tests[active]?.testName ===
                  "Additional Questions" ||
                detailReport?.tests[active]?.referenceTypeId === 3 ||
                detailReport?.tests[active]?.referenceTypeId === 4 ||
                detailReport?.tests[active]?.referenceTypeId === 5 ? null : (
                  <div
                    className="test-topic mt-3 ms-2"
                    style={{ width: window.innerWidth > 767 ? "20%" : "100%" }}
                  >
                    <Table responsive bordered={false}>
                      <thead>
                        <th>
                          {t("Difficulty level scores", { ns: "mytest" })}
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6>{t("Easy", { ns: "mytest" })}</h6>&nbsp;
                              <ProgressBar
                                style={{ width: "50%" }}
                                variant="succ"
                                now={
                                  detailReport?.tests[active]?.easyLevelScore
                                }
                              />{" "}
                              &nbsp;
                              <p>
                                {detailReport?.tests[active]?.easyLevelScore ||
                                  0}
                                %
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6>{t("Medium", { ns: "mytest" })}</h6>&nbsp;
                              <ProgressBar
                                style={{ width: "50%" }}
                                variant="succ"
                                now={
                                  detailReport?.tests[active]?.mediumLevelScore
                                }
                              />{" "}
                              &nbsp;
                              <p>
                                {detailReport?.tests[active]
                                  ?.mediumLevelScore || 0}
                                %
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex  align-items-center">
                              <h6>{t("Hard", { ns: "mytest" })}</h6>&nbsp;
                              <ProgressBar
                                style={{ width: "50%" }}
                                variant="succ"
                                now={
                                  detailReport?.tests[active]?.hardLevelScore
                                }
                              />{" "}
                              &nbsp;
                              <p>
                                {detailReport?.tests[active]?.hardLevelScore ||
                                  0}
                                %
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
            {detailReport?.capturedImages?.length > 0 && (
              <div className="image-lists">
                <h4>{t("Proctoring", { ns: "mytest" })}</h4>
                {detailReport?.capturedImages &&
                  detailReport?.capturedImages?.map((el, i) => {
                    return (
                      <div
                        className="image-list"
                        onClick={() => {
                          setImage(el.imageURL);
                          setOpenBox(true);
                        }}
                      >
                        <img src={el.imageURL} alt="" />
                        <span className="time">{formatAMPM(el.addedDate)}</span>
                      </div>
                    );
                  })}
              </div>
            )}
          </Fragment>
        ) : (
          <div className="test-report-header">
            <div
              className="d-flex justify-content-between"
              style={{ width: "55%" }}
            >
              <img
                src="/images/back-white.svg"
                className="cursor-pointer"
                onClick={() => goBack()}
                alt=""
              />
              <h3>{t("Test Report", { ns: "mytest" })}</h3>
            </div>
            <hr style={{ color: "#fff", border: "1px solid #3D4143" }} />
            <p>
              {t(
                "Something went wrong while report generating. Please check after some time",
                { ns: "mytest" }
              )}
            </p>
          </div>
        )
      ) : (
        <Fragment>
          <div className="test-report-header">
            <div
              className="d-flex justify-content-between"
              style={{ width: "55%" }}
            >
              <img
                src="/images/back-white.svg"
                className="cursor-pointer"
                onClick={() => navigate(-1)}
                alt=""
              />
              <h3>{t("Test Report", { ns: "mytest" })}</h3>
            </div>
          </div>
          <div class="bg-white rounded p-3 mb-3">
            <div class="row hidden-phone">
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Name", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.name}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Email", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.emailId}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Test Name", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.testName}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Date(GMT)", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.testAttemptDate}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Total Scrore", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.scores}
                </label>
              </div>

              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Total Questions", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.totalTestQuestions}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Total Questions Attempts", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.totalQuestionsAttemps}
                </label>
              </div>
              <div class="col-3 form-group">
                {" "}
                <label class="labletext2" style={{ paddingLeft: "5%" }}>
                  {t("Duration", { ns: "mytest" })}
                </label>
              </div>
              <div class="col-3 form-group">
                <label class="label" style={{ paddingTop: 0 }}>
                  {detailReport?.totalTestDuration}
                </label>
              </div>
            </div>
            <div class="row mt-5 visible-phone report-details">
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Name", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 0 }}>
                    {detailReport?.name}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Email", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 0 }}>
                    {detailReport?.emailId}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Test Name", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.testName}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Date(GMT)", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.testAttemptDate}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Total Scrore", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.scores}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Total Questions", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.totalTestQuestions}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Total Questions Attempts", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.totalQuestionsAttemps}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="col-3">
                  {" "}
                  <label class="labletext2" style={{ paddingLeft: "5%" }}>
                    {t("Duration", { ns: "mytest" })}
                  </label>
                </div>
                <div class="col-9">
                  <label class="label" style={{ paddingTop: 10 }}>
                    {detailReport?.totalTestDuration}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      <Lightbox
        openBox={openBox}
        image={image}
        closeBox={() => setOpenBox(false)}
      />
    </div>
  );
}
