import { DOMAIN_DETAIL } from "../Types/types";

const intialState = {
  menuList: [
    {
      name: "Home",
      action: "home",
    },
    {
      name: "Assessments",
      action: "assessments",
    },
    {
      name: "Test Library",
      action: "test-library",
    },
    {
      name: "User",
      action: "users",
    },
    {
      name: "Reports",
      action: "reports",
    },
    {
      name: "Settings",
      action: "settings",
    },
  ],
  adminMenuList: [
    // {
    //   name: "Home",
    //   action: "home",
    // },
    // {
    //   name: "Assessments",
    //   action: "assessments",
    // },
    {
      name: "My Questions",
      action: "my-questions",
    },
    {
      name: "User",
      action: "users",
    },
    // {
    //   name: "Reports",
    //   action: "reports",
    // },
    // {
    //   name: "Settings",
    //   action: "settings",
    // },
  ],
};

export default function layoutReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case DOMAIN_DETAIL:
      stateCopy.domainDetails = action.payload;
      localStorage.setItem("domainDetails", JSON.stringify(action.payload));
      return stateCopy;
    default:
      return stateCopy;
  }
}
