import { replaceObjectInArray } from '../../utils/miscelleneous';
import {
    GET_GAPPEO_STATS, GET_SEARCH_RESULTS, GET_NEXT_SEARCH_RESULTS, NO_FOUND, RESET_SEARCH_RESULT, RESET_TEST_LOADING, RESET_TEST_DETAIL, GET_LANGUAGES, HANDLE_LANGUAGE, GET_TEST_LIST, GET_NON_CORE_TEST, SELECT_ONE, CONFIRM_REPLACE, REMOVE_FROM_SELECTED,
    RESET_SELECTED_TEST, REORDER_TEST, CREATE_CUSTOM_TEST, UPDATE_TEST_NAME, GET_TEST_DETAILS,
    RESET_QUESTIONS, GET_ADDITIONAL_QUESTION, GET_NEXT_ADDITIONAL_QUESTION, NO_QUESTION, SAVE_SELECTED_QUESTION, SELECT_ONE_QUESTION, GET_TEST_BY_ID, REMOVE_FROM_SELECTED_QUESTION,
    GET_QUESTION_TYPES, GET_QUESTION_LEVELS,
    RESET_OPTIONS, RESET_QUESTION, SET_QUES_TITLE, SET_OPTIONS, SET_RIGHT_ANSWER, SAVED_QUESTION, DELETE_QUESTION, SET_ERRORS, SET_QUESTION_DETAILS,
    GET_TENENT_USERS, GET_TEMPLATE, CLOSE_INVITE_MODAL, PREVIEW_TEMPLATE, CHANGE_TEMPLATE, UPDATE_TEMPLATE, GET_SKILLS_TYPES, GET_SKILLS_TOPIC, GET_SKILLS_QUESTIONS, GET_CODING_QUESTION_LANGUAGES, GET_CODING_QUESTION_RETURN_TYPES,
    CREATE_CODING_QUESTION, RESET_CODING_QUESTION, QUESTION_TAGS, DEFAULT_CODING_QUESTION, LIST_CODING_QUESTION, LIST_NEXT_CODING_QUESTION, CODING_QUESTION_DETAILS_BY_ID, SET_ACTIVE_TABS,
    SELECT_CODING_QUES, REMOVE_FROM_SELECT, SAVE_SELECT_CODING_QUESTION, CREATE_CODING_TEST, UPDATE_CODING_TEST, EDIT_CODE_QUESTION, GET_ONE_CODING_TEST
} from '../Types/types';

let intialState = {
    noFound: false,
    selectedTests: [],
    testLoading: true,
    tests: [],
    deleteIds: [],
    nonCoreTests: [{ title: 'Workplace Attitude', level: 'Advanced', question: 20, time: 20, type: 'behavioural', isSelected: false },
    { title: 'Leadership skills', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false },
    { title: 'Communication skills', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false },
    { title: 'Time Management', level: 'Advanced', question: 20, time: 20, type: 'behavioural', isSelected: false },
    { title: 'Human Resource', level: 'Advanced', question: 20, time: 20, type: 'cultural', isSelected: false },
    { title: 'People skills', level: 'Advanced', question: 20, time: 20, type: 'cultural', isSelected: false },
    { title: 'Team Spirit', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false }
    ],
    questionType: [],
    questionLevel: [],
    additionalQuestions: [],
    isQuestionLoading: true,
    noQuestion: [],
    selectedQuestion: [],
    questionOptions: [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
    { index: 1, questionOption: '', iscorrect: false, optionError: '' },
    { index: 2, questionOption: '', iscorrect: false, optionError: '' },
    { index: 3, questionOption: '', iscorrect: false, optionError: '' }
    ],
    isTemplate: false,
    editTemplate: false,
    templates: '',
    skillType: [],
    skillTopic: [],
    skillQuestions: [],
    active: 'MCQS',
    // * for coding questions
    codingQuestionLanguagesOptions: [],
    codingQuestionReturnTypesOptions: [],
    createdCodingQuestionResponse: [],
    codingQuestionListResponse: [],
    selectedCodingQuestion: [],
    codingQuestionByIdResponse: '',
    creatingCodingQuestion: false,
    loadingCodingQuestion: true,
    loadingCodingQuestionById: true,
    skills: [],
    topics: []
}



export default function testLibraryReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {

        case GET_GAPPEO_STATS:
            stateCopy.testLibStats = action.payload;
            stateCopy.testLoading = true;
            return stateCopy;
        case GET_SEARCH_RESULTS:
            stateCopy.suggestions = action.payload.searchTestLibraryList;
            stateCopy.totalCount = action.payload.totalCount;
            stateCopy.noFound = false;
            return stateCopy;
        case GET_NEXT_SEARCH_RESULTS:
            stateCopy.suggestions = stateCopy.suggestions.concat(action.payload.searchTestLibraryList);
            stateCopy.totalCount = action.payload.totalCount;
            stateCopy.noFound = false;
            return stateCopy;
        case NO_FOUND:
            stateCopy.suggestions = [];
            stateCopy.noFound = true;
            return stateCopy;
        case RESET_SEARCH_RESULT:
            stateCopy.suggestions = [];
            return stateCopy;
        case RESET_TEST_LOADING:
            stateCopy.testLoading = true;
            return stateCopy;
        case GET_LANGUAGES:
            stateCopy.languages = action.payload;
            return stateCopy;
        case HANDLE_LANGUAGE:
            stateCopy.tests = stateCopy.tests.map(el => [{ ...el, languageId: action.payload, isSelected: false }][0]);
            stateCopy.tests.forEach(item => {
                const foundIndex = stateCopy.selectedTests.find(i => ((i.testID === item.testID) && (i.testLevel === item.testLevel) && ((i.languageId === item.languageId))));
                if (foundIndex) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            })
            return stateCopy;
        case GET_TEST_LIST:
            if (action.payload.testType === 1) {
                stateCopy.languageData = action.payload.data;
            } else {
                action.payload.forEach(item => {
                    const foundIndex = stateCopy.selectedTests.find(i => ((i.testID === item.testID) && (i.testLevel === item.testLevel)));
                    if (foundIndex) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }
                })
                stateCopy.tests = action.payload.map(el => [{ ...el, languageId: 1 }][0]);
            }
            stateCopy.testLoading = false;
            return stateCopy;
        case GET_NON_CORE_TEST:
            action.payload.forEach(item => {
                const foundIndex = stateCopy.selectedTests.find(i => ((i.testID === item.testID) && (i.testLevel === item.testLevel)));
                if (foundIndex) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            })
            stateCopy.nonCoreTests = action.payload;
            stateCopy.testLoading = false;
            return stateCopy;

        case SELECT_ONE:
            console.log(action.payload)
            if (action.payload.checked) {
                const found = stateCopy.selectedTests.find(el => ((el.testID === Number(action.payload.id) && (el.testLevel === action.payload.data.testLevel) && (el.languageId === Number(action.payload.data.languageId)))));
                if (found === undefined) {
                    stateCopy.selectedTests = [...stateCopy.selectedTests, { ...action.payload.data, isSelected: true, orderId: stateCopy.selectedTests?.length + 1 }];
                }
                stateCopy.deleteIds = stateCopy.deleteIds.filter(el => ((el.testID !== Number(action.payload.id)) || (el.testLevel !== action.payload.data.testLevel) || (el.languageId !== Number(action.payload.data.languageId))));
                if (action.payload.type === 'test') {
                    stateCopy.tests.forEach((el) => {
                        if (((el.testID === Number(action.payload.id) && (el.testLevel === action.payload.data.testLevel) && (el.languageId === Number(action.payload.data.languageId))))) {
                            el.isSelected = true
                        }
                    })
                } else {
                    stateCopy.nonCoreTests.forEach((el) => {
                        if (((el.testID === Number(action.payload.id) && (el.testLevel === action.payload.data.testLevel)))) {
                            el.isSelected = true
                        }
                    })
                }
            }
            else {
                stateCopy.selectedTests = stateCopy.selectedTests.filter(el => ((el.testID !== Number(action.payload.id)) || (el.testLevel !== action.payload.data.testLevel) && (el.languageId !== Number(action.payload.data.languageId)))).map((el, i) => ({ ...el, orderId: i }));
                if (action.payload.testDetailId) {
                    stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
                }
                if (action.payload.type === 'test') {
                    stateCopy.tests.forEach(el => {
                        if (((el.testID === Number(action.payload.id) && (el.testLevel === action.payload.data.testLevel) && (el.languageId === Number(action.payload.data.languageId))))) {
                            el.isSelected = false
                        }
                    })
                } else {
                    stateCopy.nonCoreTests.forEach((el) => {
                        if (((el.testID === Number(action.payload.id)))) {
                            el.isSelected = false
                        }
                    })
                }
            }

            return stateCopy;
        case CONFIRM_REPLACE:
            stateCopy.tests.forEach(el => {
                if (((el.testID === Number(action.payload.data.testID) && (el.testLevel === action.payload.data.testLevel) && (el.languageId === action.payload.data.languageId)))) {
                    el.isSelected = false
                }
                else if (((el.testID === Number(action.payload.newData.testID) && (el.testLevel === action.payload.newData.testLevel) && (el.languageId === action.payload.newData.languageId)))) {
                    el.isSelected = true
                }
            })
            stateCopy.selectedTests = replaceObjectInArray(stateCopy.selectedTests, action.payload.data.testID, action.payload.newData);
            if (action.payload?.data?.testDetailId) {
                stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload?.data?.testDetailId)];
            }
            return stateCopy;
        case REMOVE_FROM_SELECTED:
            stateCopy.selectedTests = stateCopy.selectedTests.filter(el => ((el.testID !== action.payload.testID) || (el.testLevel !== action.payload.testLevel) || (el.languageId !== Number(action.payload.languageId)))).map((el, i) => ({ ...el, orderId: i }));

            if (action.payload.testDetailId) {
                stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
            }
            stateCopy.tests.forEach(el => {
                if (((el.testID === Number(action.payload.testID)) && (el.testLevel === action.payload.testLevel) && (el.languageId === Number(action.payload.languageId)))) {
                    el.isSelected = false
                }
            })
            stateCopy.nonCoreTests.forEach((el) => {
                if ((el.testID === Number(action.payload.testID))) {
                    el.isSelected = false
                }
            })
            return stateCopy;
        case RESET_SELECTED_TEST:
            stateCopy.selectedTests = [];
            return stateCopy;
        case REORDER_TEST:
            const selectedTests = Array.from(stateCopy.selectedTests);
            const [reorderItem] = selectedTests.splice(action.payload.source.index, 1);
            selectedTests.splice(action.payload.destination.index, 0, reorderItem);
            selectedTests.forEach((el, i) => {
                if (el.orderId !== i + 1) {
                    el.orderId = i + 1;
                }
            });
            stateCopy.selectedTests = selectedTests;
            return stateCopy;
        case CREATE_CUSTOM_TEST:
            stateCopy.testId = action.payload;
            localStorage.setItem('testId', action.payload);
            stateCopy.deleteIds = [];
            return stateCopy;
        case UPDATE_TEST_NAME:
            stateCopy.testDetails.testName = action.payload.testName;
            return stateCopy;
        case RESET_TEST_DETAIL:
            stateCopy.testDetails = null;
            return stateCopy;
        case GET_TEST_DETAILS:
            stateCopy.testDetails = action.payload;
            stateCopy.tests.forEach(item => {
                const foundIndex = stateCopy.selectedTests.find(i => ((i.testID === item.testID) && (i.testLevel === item.testLevel)));
                if (foundIndex) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            })
            stateCopy.selectedTests = action.payload.testDetails?.tests;
            stateCopy.selectedQuestion = action.payload.testDetails?.questions;
            return stateCopy;
        //My Questions
        case SET_ACTIVE_TABS:
            stateCopy.active = action.payload;
            return stateCopy;
        case RESET_QUESTIONS:
            stateCopy.additionalQuestions = [];
            stateCopy.isQuestionLoading = true;
            return stateCopy;
        case GET_ADDITIONAL_QUESTION:
            action.payload.questionList.forEach(item => {
                const foundIndex = stateCopy.selectedQuestion.find(i => i.questionID === item.questionID);
                if (foundIndex) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
            })
            stateCopy.additionalQuestions = action.payload.questionList;
            stateCopy.totalQues = action.payload.totalCount;
            stateCopy.noQuestion = false;
            stateCopy.isQuestionLoading = false;
            return stateCopy;
        case GET_NEXT_ADDITIONAL_QUESTION:
            action.payload.questionList.forEach(item => {
                const foundIndex = stateCopy.selectedQuestion.find(i => i.questionID === item.questionID);
                if (foundIndex) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
            })
            stateCopy.additionalQuestions = stateCopy.additionalQuestions.concat(action.payload.questionList);
            stateCopy.totalQues = action.payload.totalCount;
            stateCopy.noQuestion = false;
            stateCopy.isQuestionLoading = false;
            return stateCopy;
        case NO_QUESTION:
            stateCopy.additionalQuestions = [];
            stateCopy.noQuestion = true;
            stateCopy.isQuestionLoading = false;
            stateCopy.skillQuestions = [];
            return stateCopy;
        case SAVE_SELECTED_QUESTION:
            stateCopy.selectedQuestion = action.payload;
            return stateCopy;
        case SELECT_ONE_QUESTION:
            if (action.payload.checked) {
                const found = stateCopy.selectedQuestion.find(el => el.questionID === Number(action.payload.id));
                if (found === undefined) {
                    stateCopy.selectedQuestion = [...stateCopy.selectedQuestion, { ...action.payload.data, isChecked: true }];
                }
                stateCopy.deleteIds = stateCopy.deleteIds.filter(el => el.questionID !== Number(action.payload.id));
                stateCopy.additionalQuestions.forEach((el) => {
                    if (el.questionID === Number(action.payload.id)) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.selectedQuestion = stateCopy.selectedQuestion.filter(el => el.questionID !== Number(action.payload.id));
                if (action.payload.testDetailId) {
                    stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
                }
                stateCopy.additionalQuestions.forEach(el => {
                    if (el.questionID === Number(action.payload.id)) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy;
        case GET_TEST_BY_ID:
            stateCopy.selectedQuestion = action.payload.questions;
            return stateCopy;
        case REMOVE_FROM_SELECTED_QUESTION:
            console.log(action.payload)
            stateCopy.selectedQuestion = stateCopy.selectedQuestion.filter(el => (el.questionID !== Number(action.payload.questionID)));
            if (action.payload.testDetailId) {
                stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
            }
            return stateCopy;
        case RESET_QUESTION:
            stateCopy.qTitle = '';
            stateCopy.templates = '';
            stateCopy.questionOptions = [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
            { index: 1, questionOption: '', iscorrect: false, optionError: '' },
            { index: 2, questionOption: '', iscorrect: false, optionError: '' },
            { index: 3, questionOption: '', iscorrect: false, optionError: '' }
            ]
            return stateCopy;
        case RESET_OPTIONS:
            stateCopy.questionOptions = [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
            { index: 1, questionOption: '', iscorrect: false, optionError: '' },
            { index: 2, questionOption: '', iscorrect: false, optionError: '' },
            { index: 3, questionOption: '', iscorrect: false, optionError: '' }
            ]
            return stateCopy;
        case GET_QUESTION_TYPES:
            stateCopy.questionType = action.payload;
            return stateCopy;
        case GET_QUESTION_LEVELS:
            stateCopy.questionLevel = action.payload;
            return stateCopy;
        case SET_QUES_TITLE:
            stateCopy.qTitle = action.payload;
            return stateCopy;
        case SET_OPTIONS:
            if (action.payload.key === 0) {
                const answersns = stateCopy.questionOptions.map(answer => {
                    if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
                    return answer
                })
                stateCopy.questionOptions = answersns;
            }
            else if (action.payload.key === 1) {
                const answersns = stateCopy.questionOptions.map(answer => {
                    if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
                    return answer
                })
                stateCopy.questionOptions = answersns;
            }
            else if (action.payload.key === 2) {
                const answersns = stateCopy.questionOptions.map(answer => {
                    if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
                    return answer
                })
                stateCopy.questionOptions = answersns;
            }
            else if (action.payload.key === 3) {
                const answersns = stateCopy.questionOptions.map(answer => {
                    if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
                    return answer
                })
                stateCopy.questionOptions = answersns;
            }
            return stateCopy;
        case SET_RIGHT_ANSWER:
            if (Number(action.payload.quesType) === 2) {
                stateCopy.questionOptions[action.payload.key].iscorrect = !action.payload.checked;
            } else {
                stateCopy.questionOptions.forEach(el => {
                    if (el.index === action.payload.idx) {
                        el.iscorrect = true
                    } else {
                        el.iscorrect = false
                    }
                })
            }
            return stateCopy;
        case SET_ERRORS:
            const answersns = stateCopy.questionOptions.map(answer => {
                if (answer.index === action.payload) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: `Option can't be empty` }
                return answer
            })
            stateCopy.questionOptions = answersns;
            return stateCopy;
        case SAVED_QUESTION:
            stateCopy.additionalQuestions = [...stateCopy.additionalQuestions, action.payload];
            stateCopy.deleteIds = [];
            return stateCopy;
        case DELETE_QUESTION:
            stateCopy.additionalQuestions = stateCopy.additionalQuestions.filter(el => el.questionID !== action.payload.questionID)
            return stateCopy;
        case SET_QUESTION_DETAILS:
            stateCopy.qTitle = action.payload?.question;
            stateCopy.questionOptions = action.payload?.questionOptions ? action.payload?.questionOptions.map((el, i) => ({ ...el, index: i })) : [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
            { index: 1, questionOption: '', iscorrect: false, optionError: '' },
            { index: 2, questionOption: '', iscorrect: false, optionError: '' },
            { index: 3, questionOption: '', iscorrect: false, optionError: '' }
            ];
            return stateCopy;
        case GET_TENENT_USERS:
            stateCopy.tenentUsers = action.payload.map(el => [{ label: el.name, value: el.emailId }][0]);
            stateCopy.isTemplate = false;
            return stateCopy;
        case PREVIEW_TEMPLATE:
            let re = '<%EmailBody%>'
            stateCopy.templateText = stateCopy.templateText.replace(re, stateCopy.emailBody);
            stateCopy.templateText = stateCopy.templateText.replace('<%footer%>', stateCopy.footer);
            return stateCopy;
        case GET_TEMPLATE:
            stateCopy.isTemplate = true;
            stateCopy.editTemplate = action.payload.edit;
            stateCopy.templateType = action.payload.type;
            stateCopy.templateId = action.payload.data.templateId;
            stateCopy.instruction = action.payload.data.additionalInstruction;
            stateCopy.emailBody = action.payload.data.emailBody;
            stateCopy.footer = action.payload.data.footer;
            // stateCopy.templates = 
            stateCopy.templates = action.payload.data.template;
            stateCopy.templateText = action.payload.data.templateText;
            return stateCopy;
        case CLOSE_INVITE_MODAL:
            stateCopy.isTemplate = false;
            stateCopy.editTemplate = false;
            stateCopy.instruction = ''
            // stateCopy.templates = '';
            return stateCopy;
        case CHANGE_TEMPLATE:
            if (action.payload.type === 'instruction') {
                stateCopy.instruction = action.payload.content;
            } else if (action.payload.type === 'email') {
                stateCopy.emailBody = action.payload.content;
            } else {
                stateCopy.footer = action.payload.content;
            }
            console.log(stateCopy.emailBody, stateCopy.footer)
            return stateCopy;
        case UPDATE_TEMPLATE:
            stateCopy.templates = action.payload.template;
            stateCopy.instruction = action.payload.additionalInstruction;
            stateCopy.editTemplate = false;
            return stateCopy;



        // Get skills list
        case GET_SKILLS_TYPES:
            stateCopy.skillType = action.payload.skills;
            return stateCopy;

        // Get skills list
        case GET_SKILLS_TOPIC:
            stateCopy.skillTopic = action.payload.skillTopics;
            return stateCopy;

        // Get skills Questions
        case GET_SKILLS_QUESTIONS:
            stateCopy.skillQuestions = action.payload.questionList;
            stateCopy.isQuestionLoading = false;
            return stateCopy;

        // *For Coding Questions
        case GET_CODING_QUESTION_LANGUAGES:
            stateCopy.codingQuestionLanguagesOptions = action?.payload.languages;
            return stateCopy;

        case GET_CODING_QUESTION_RETURN_TYPES:
            stateCopy.codingQuestionReturnTypesOptions = action?.payload;
            return stateCopy;

        case CREATE_CODING_QUESTION:
            if (action.payload.request?.addFromList) {
                stateCopy.selectedCodingQuestion = [{ ...action.payload.data1.question, isSelected: true }, ...stateCopy.selectedCodingQuestion];
                stateCopy.codingQuestionListResponse = [{ ...action.payload.data1.question, isSelected: true }, ...stateCopy.codingQuestionListResponse];
            } else {
                stateCopy.createdCodingQuestionResponse = action?.payload.data;
                stateCopy.creatingCodingQuestion = true;
            }
            return stateCopy;
        case RESET_CODING_QUESTION:
            stateCopy.loadingCodingQuestion = true;
            return stateCopy;
        case QUESTION_TAGS:
            stateCopy.qTags = action.payload.map(el => [{ label: el, value: el }][0]);
            return stateCopy;
        case DEFAULT_CODING_QUESTION:
            stateCopy.selectedCodingQuestion = action?.payload?.questions.map(question => ({
                ...question,
                isSelected: true
            }));
            stateCopy.loadingCodingQuestion = false;
            return stateCopy;

        case LIST_CODING_QUESTION:
            stateCopy.codingQuestionListResponse = stateCopy.selectedCodingQuestion.concat(action?.payload?.questions.map(question => ({
                ...question,
                isSelected: false
            })));
            stateCopy.total = action.payload.totalCount;
            stateCopy.loadingCodingQuestion = false;
            stateCopy.loadingCodingQuestionById = true;
            return stateCopy;
        case LIST_NEXT_CODING_QUESTION:
            stateCopy.codingQuestionListResponse = stateCopy.codingQuestionListResponse.concat(action?.payload?.questions.map(question => ({
                ...question,
                isSelected: false
            })));
            stateCopy.loadingCodingQuestion = false;
            stateCopy.loadingCodingQuestionById = true;
            return stateCopy;
        case SELECT_CODING_QUES:
            console.log(action.payload)
            if (action.payload.checked) {
                const found = stateCopy.selectedCodingQuestion.find(el => el.id === Number(action.payload.id));
                if (found === undefined) {
                    stateCopy.selectedCodingQuestion = [...stateCopy.selectedCodingQuestion, { ...action.payload.data, isSelected: true }];
                }
                stateCopy.deleteIds = stateCopy.deleteIds.filter(el => el.id !== Number(action.payload.id));
                stateCopy.codingQuestionListResponse.forEach((el) => {
                    if (el.id === Number(action.payload.id)) {
                        el.isSelected = true
                    }
                })
            }
            else {
                stateCopy.selectedCodingQuestion = stateCopy.selectedCodingQuestion.filter(el => el.id !== Number(action.payload.id));
                // if (action.payload.testDetailId) {
                //     stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
                // }
                stateCopy.codingQuestionListResponse.forEach(el => {
                    if (el.id === Number(action.payload.id)) {
                        el.isSelected = false
                    }
                })
            }
            return stateCopy;
        case REMOVE_FROM_SELECT:
            stateCopy.selectedCodingQuestion = stateCopy.selectedCodingQuestion?.filter(el => el.id !== action.payload.id)
            stateCopy.codingQuestionListResponse = stateCopy.codingQuestionListResponse?.map(el => ({
                ...el,
                isSelected: el.id === action.payload.id ? false : el.isSelected
            }))
            return stateCopy;
        case SAVE_SELECT_CODING_QUESTION:
            stateCopy.selectedCodingQuestion = action.payload;
            return stateCopy;
        case CODING_QUESTION_DETAILS_BY_ID:
            stateCopy.codingQuestionByIdResponse = action?.payload;
            stateCopy.loadingCodingQuestionById = false;
            return stateCopy;
        case CREATE_CODING_TEST:
            stateCopy.selectedTests = [...stateCopy.selectedTests, action.payload]
            return stateCopy;
        case UPDATE_CODING_TEST:
            stateCopy.selectedTests = replaceObjectInArray(stateCopy.selectedTests, action.payload.testID, action.payload);
            return stateCopy;
        case GET_ONE_CODING_TEST:
            console.log(action.payload)
            stateCopy.codingTestById = action?.payload;
            stateCopy.selectedCodingQuestion = action.payload.questions.map(el => [{ ...el, isSelected: true }][0]);
            return stateCopy;
        case EDIT_CODE_QUESTION:
            stateCopy.selectedCodingQuestion.forEach(el => {
                if (el.id === action.payload.id) {
                    el.duration = parseInt(action.payload.duration)
                    el.score = parseInt(action.payload.score)
                }
            })
            return stateCopy;
        default:
            return stateCopy;
    }
}
