import React, { useEffect, useState } from 'react';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { clearErrors } from '../Redux/ActionCreators/errorAction';
import useScreenRecording from "use-screen-recording";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Footer from './footer';

export default function Header() {
    const [active, setActive] = useState('test-library');
    const [isOpen, setIsOpen] = useState(false);
    const [video, setVideo] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isRecording, recording, toggleRecording } = useScreenRecording();

    useEffect(() => {
        setActive(window.location.pathname.split('/')[1]);
    }, [((active !== 'gappeo-tests') || (active !== 'my-questions'))])

    const { menuList, adminMenuList } = useSelector(state => state.layout);

    const details = JSON.parse(localStorage.getItem("profileDetails"));
    const roleId = details?.roleId;
    const superAdminId = "1"

    // Filter the menuList based on roleId. If roleId is "1", exclude the first item.
    const filteredMenuList = roleId === superAdminId ? adminMenuList : menuList;


    const error = useSelector(state => state.error);

    const goToActive = (action) => {
        console.log(action)
        if (window.innerWidth > 767 && ((action === 'gappeo-tests') || (action === 'my-questions'))) {
            setActive('test-library');
        }
        else {
            setActive(action);
        }
        navigate(action);
    }

    const logout = () => {
        localStorage.clear();
        window.location.href = '/login'
    }

    const snackClosed = () => {
        dispatch(clearErrors())
    }

    function convertBlobToFile(blob, size) {
        // Create a new File object with the specified size.
        const file = new File([blob], 'file.txt', {
            type: blob.type,
            size: size,
        });

        // Return the new File object.
        return file;
    }

    const toggle = (e) => {
        console.log(e.target)
        toggleRecording();
        console.log(recording)
        setVideo(recording)
        // convertBlobToFile(URL.createObjectURL(recording), 1025)
    }

    const domainDetails = JSON.parse(localStorage.getItem('domainDetails'));
    return (
        <div className='gappeo-holder'>
            <div className="bg-dark p-3 header-container" style={{ zIndex: (window.innerWidth < 767 && (window.location.pathname === '/home/select-test' || window.location.pathname === '/home/add-question' || window.location.pathname === '/home/select-question' || window.location.pathname === '/home/invite-preview')) ? -1 : 5 }}>
                {
                    window.innerWidth > 767 ?
                        <div className="row">
                            <div className="col-2 ps-5 logo">
                                <div className="txt1 fw-bold text-uppercase me-auto ms-auto cursor-pointer" onClick={() => roleId === superAdminId ? navigate('/users') : navigate('/home')}>
                                    <div className='text-center' style={{ backgroundImage: `url(${domainDetails?.isWhiteLabel === 1 ? domainDetails?.logoImage : "/images/logo.png"})`, backgroundPosition: 'center center', backgroundSize: "contain", backgroundRepeat: 'no-repeat', width: '100%', height: 40 }}>
                                        {/* <img src={localStorage.getItem('logo')} width={69} height={55} alt='' /> */}
                                    </div>

                                    {/* <img src={domainDetails?.logoImage ? domainDetails?.logoImage : "/images/logo.png"} alt="gappeologo" width="110px" /> */}

                                </div>
                            </div>

                            <div className="col-7">
                                <ul className="menus d-flex justify-content-around"
                                    style={{ listStyle: 'none' }}>
                                    {filteredMenuList && filteredMenuList.map((el, i) => {
                                        return (
                                            <li key={i} className={((active === el.action)) ? 'active' : ''} onClick={() => el.name === 'Test Library' ? null : goToActive(el.action)}>
                                                {/* {el.name} */}
                                                {el.name === 'Test Library' ? (
                                                    <div className="dropdown" >
                                                        <div className="dropdown-toggle" href="" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {t(`${el.name}`)}
                                                        </div>
                                                        <div className={"dropdown-menu mt-2"} aria-labelledby="navbarDropdown">
                                                            <div className="dropdown-item" onClick={() => goToActive('gappeo-tests')}>{t('Gappeo Tests')}</div>
                                                            <div className="dropdown-item" onClick={() => goToActive('my-tests')}>{t('My Tests')}</div>
                                                            <div className="dropdown-item" onClick={() => goToActive('my-questions')} >{t('My Questions')}</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <span>{t(`${el.name}`)}</span>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <div className="col-2 help-support d-flex align-items-center" style={{ paddingLeft: 90, paddingRight: 90, paddingTop: 0 }}>
                                <a className="btn" role="button" id="button-addon2"
                                    target="_blank" href="https://gappeo-help.freshdesk.com/support/home" rel="noreferrer">
                                    <img src='/images/help.png' alt='help' />

                                </a>

                                <div className="user-sign-option" >
                                    <ul style={{ listStyle: 'none', paddingTop: 0 }}>
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" id="Dropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                style={{ color: '#fff', textDecoration: 'none' }}>
                                                {localStorage.getItem('name')}
                                                {/* Rajat jain dmdmedmednfdm */}
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="Dropdown">
                                                <li onClick={() => navigate('/profile')}>
                                                    <div className="dropdown-item">{t('Company Profile')}</div>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li onClick={logout}><div className="dropdown-item">{t('Sign Out')}</div></li>
                                            </ul>
                                        </li>
                                    </ul >
                                </div >

                            </div >
                        </div >
                        :
                        <div className="row align-items-center">
                            <div className="col-2 ps-5 logo">
                                <div className="txt1 fw-bold text-uppercase me-auto ms-auto">

                                    {/* <div style={{ backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '100%', height: 65 }}></div> */}
                                    <img src="/images/logo.png" alt="gappeologo" width="110px" />

                                </div>
                            </div>
                            <div className="col-2 help-support d-flex align-items-center"
                                style={{ paddingLeft: '15%', paddingTop: 0 }}>
                                <a className="btn" role="button" id="button-addon2"
                                    target="_blank" href="https://gappeo-help.freshdesk.com/support/home" rel="noreferrer">
                                    <img src='/images/help.png' alt='help' />

                                </a>

                                <div className="user-sign-option" >
                                    <ul style={{ listStyle: 'none', paddingLeft: '15%', paddingTop: 0 }}>
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" href="#" id="Dropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                style={{ color: '#fff', textDecoration: 'none' }}>
                                                {localStorage.getItem('name')}
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="Dropdown">
                                                <li onClick={() => navigate('/profile')}>
                                                    <div className="dropdown-item">{t('Company Profile')}</div>
                                                </li>

                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li onClick={logout}><div className="dropdown-item">{t('Sign Out')}</div></li>
                                            </ul>
                                        </li>
                                    </ul >
                                </div >

                            </div >

                            <div className="user-menu-section">

                                <div className="dropdown-toggle menu-icon" id="Dropdown1" role="button"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    style={{ color: '#fff', textDecoration: 'none' }}>
                                    <img src='/images/menu.png' style={{ color: '#fff', width: 28 }} alt='' />
                                    {/* <i className="bi bi-list" style={{ color: '#fff', fontSize: 28 }}></i> */}
                                </div>

                                <ul className="dropdown-menu dropdown-menu-end mobile-dropdown-menu-items"
                                    aria-labelledby="Dropdown">

                                    {
                                        filteredMenuList && filteredMenuList.filter(el => el.name !== 'Test Library')?.map((el, i) => {
                                            return (
                                                <li key={i} className={active === el.action ? 'active dropdown-item' : 'dropdown-item'} onClick={() => goToActive(el.action)}>{el.name}</li>
                                            )
                                        })
                                    }
                                    <li className={active === 'gappeo-tests' ? 'active dropdown-item' : 'dropdown-item'} onClick={() => goToActive('gappeo-tests')}>{t('Gappeo Tests')}</li>
                                    <li className={active === 'my-tests' ? 'active dropdown-item' : 'dropdown-item'} onClick={() => goToActive('my-tests')}>{t('My Tests')}</li>
                                    <li className={active === 'my-questions' ? 'active dropdown-item' : 'dropdown-item'} onClick={() => goToActive('my-questions')} >{t('My Questions')}</li>
                                </ul>
                            </div>

                        </div >
                }
                <div>
                    {/* <button onClick={toggle}>
                        {isRecording ? "Stop" : "Start Recording"}
                    </button>
                    {!!recording && <button onClick={() => setIsOpen(true)}>
                        Open Recording
                    </button>} */}

                    {/* {!!recording && (
                        <video autoPlay src={recording && URL.createObjectURL(recording)} />
                    )} */}
                </div>
            </div >
            <div className='layout-container'>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    autoHideDuration={3000}
                    onClose={snackClosed}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <React.Suspense fallback={''}>
                    <Outlet />
                </React.Suspense>
                {domainDetails?.isWhiteLabel === 1 ?
                    <div className='text-center mt-5 mb-3'>
                        <Footer />
                    </div> :
                    null
                }
            </div>
            <Modal
                show={isOpen}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal "}
            >
                <div className="p-4" >
                    <h1>
                        {!!recording && (
                            <video autoPlay src={recording && URL.createObjectURL(recording)} width={400} height={400} controls />
                        )}
                    </h1>
                    <div className="save-btn">
                        {/* <button onClick={removeConfirm}>Yes</button>&nbsp;&nbsp; */}
                        <button onClick={() => setIsOpen(false)}>{t('No')}</button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
