//errors

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//auth
export const LOGIN_USER = 'LOGIN_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';


//Test library
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const HANDLE_LANGUAGE = 'HANDLE_LANGUAGE';
export const GET_GAPPEO_STATS = 'GET_GAPPEO_STATS';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_NEXT_SEARCH_RESULTS = 'GET_NEXT_SEARCH_RESULTS';
export const NO_FOUND = 'NO_FOUND';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const RESET_TEST_LOADING = 'RESET_TEST_LOADING';
export const RESET_TEST_DETAIL = 'RESET_TEST_DETAIL';
export const GET_TEST_LIST = 'GET_TEST_LIST';
export const GET_NON_CORE_TEST = 'GET_NON_CORE_TEST';
export const SELECT_ONE = 'SELECT_ONE';
export const CONFIRM_REPLACE = 'CONFIRM_REPLACE';
export const REMOVE_FROM_SELECTED = 'REMOVE_FROM_SELECTED';
export const VALIDATE_NAME = 'VALIDATE_NAME';
export const RESET_SELECTED_TEST = 'RESET_SELECTED_TEST';
export const REORDER_TEST = 'REORDER_TEST';
export const CREATE_CUSTOM_TEST = 'CREATE_CUSTOM_TEST';
export const UPDATE_TEST = 'UPDATE_TEST';
export const UPDATE_TEST_NAME = 'UPDATE_TEST_NAME';
export const GET_TEST_DETAILS = 'GET_TEST_DETAILS';
export const SET_ACTIVE_TABS = 'SET_ACTIVE_TABS';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';
export const GET_ADDITIONAL_QUESTION = 'GET_ADDITIONAL_QUESTION';
export const GET_NEXT_ADDITIONAL_QUESTION = 'GET_NEXT_ADDITIONAL_QUESTION';
export const NO_QUESTION = 'NO_QUESTION';
export const SAVE_SELECTED_QUESTION = 'SAVE_SELECTED_QUESTION';
export const SELECT_ONE_QUESTION = 'SELECT_ONE_QUESTION';
export const REMOVE_FROM_SELECTED_QUESTION = 'REMOVE_FROM_SELECTED_QUESTION';
export const GET_QUESTION_TYPES = 'GET_QUESTION_TYPES';
export const GET_QUESTION_LEVELS = 'GET_QUESTION_LEVELS';
export const RESET_OPTIONS = 'RESET_OPTIONS';
export const RESET_QUESTION = 'RESET_QUESTION';
export const SET_QUES_TITLE = 'SET_QUES_TITLE';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_RIGHT_ANSWER = 'SET_RIGHT_ANSWER';
export const SET_ERRORS = 'SET_ERRORS';
export const SAVED_QUESTION = 'SAVED_QUESTION';
export const EDITED_QUESTION = 'EDITED_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const SET_QUESTION_DETAILS = 'SET_QUESTION_DETAILS';
export const GET_TENENT_USERS = 'GET_TENENT_USERS';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const CLOSE_INVITE_MODAL = 'CLOSE_INVITE_MODAL';
export const CHANGE_TEMPLATE = 'CHANGE_TEMPLATE';
export const PREVIEW_TEMPLATE = 'PREVIEW_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const SEND_INVITES = 'SEND_INVITES';
export const SELECT_CODING_QUES = 'SELECT_CODING_QUES';
export const REMOVE_FROM_SELECT = 'REMOVE_FROM_SELECT';
export const SAVE_SELECT_CODING_QUESTION = 'SAVE_SELECT_CODING_QUESTION';


//Candidate test
export const GET_TESTS_DETAILS = 'GET_TESTS_DETAILS';
export const TEST_REGISTER = 'TEST_REGISTER'
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const INTITATE_TEST = 'INTITATE_TEST';
// export const RESET_TEST_SUMMARY = 'RESET_TEST_SUMMARY';
export const TEST_DETAILS = 'TEST_DETAILS';
export const GET_QUESTION = 'GET_QUESTION';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const TEST_STATUS_UPDATE = 'TEST_STATUS_UPDATE';
export const GET_SKILLS_TYPES = 'GET_SKILLS_TYPES';
export const GET_SKILLS_TOPIC = 'GET_SKILLS_TOPIC';
export const GET_SKILLS_QUESTIONS = 'GET_SKILLS_QUESTIONS';
export const RUN_CODE = 'RUN_CODE';
export const SUBMIT_CODE = 'SUBMIT_CODE';
export const GET_STARTER_CODE = 'GET_STARTER_CODE';

//MY ASSESSMENTS
export const GET_MY_ASSESSMENTS = 'GET_MY_ASSESSMENTS';
export const GET_NEXT_MY_ASSESSMENTS = 'GET_NEXT_MY_ASSESSMENTS';
export const NO_TESTS = 'NO_TESTS';
export const RESET_TEST_LOADER = 'RESET_TEST_LOADER';
export const RESET_LOADING = 'RESET_LOADING';
export const GET_REPORT1 = 'GET_REPORT1';
export const GET_REPORT = 'GET_REPORT';
export const GET_NEXT_REPORT = 'GET_NEXT_REPORT';
export const GET_OLD_REPORT = 'GET_OLD_REPORT';
export const GET_OLD_NEXT_REPORT = 'GET_OLD_NEXT_REPORT';
export const GET_DETAILED_REPORT = 'GET_DETAILED_REPORT';

//MY TESTS
export const RESET_MY_TESTS = 'RESET_MY_TESTS';
export const GET_MY_TESTS = 'GET_MY_TESTS';
export const GET_NEXT_MY_TESTS = 'GET_NEXT_MY_TESTS';
export const NO_MY_TESTS = 'NO_MY_TESTS';
export const CREATE_MY_TEST = 'CREATE_MY_TEST';
export const UPDATE_MY_TEST = 'UPDATE_MY_TEST';
export const GET_QUESTION_SOURCE = 'GET_QUESTION_SOURCE';
export const GET_SKILLS = 'GET_SKILLS';
export const GET_TOPICS = 'GET_TOPICS';
export const EMPTY_TOPICS = 'EMPTY_TOPICS';
export const GET_COUNT = 'GET_COUNT';
export const GET_TEST_BY_ID = 'GET_TEST_BY_ID';

//GAPPEO TESTS
export const RESET_GAPPEO_TEST = 'RESET_GAPPEO_TEST';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_GAPPEO_TESTS = 'GET_GAPPEO_TESTS';
export const GET_NEXT_GAPPEO_TESTS = 'GET_NEXT_GAPPEO_TESTS';
export const EMPTY_GAPPEO_TEST = 'EMPTY_GAPPEO_TEST';
export const RESET_SAMPLE_QUESTIONS = 'RESET_SAMPLE_QUESTIONS';
export const GET_SAMPLE_QUESTIONS = 'GET_SAMPLE_QUESTIONS';
export const FILTERED_QUESTIONS = 'FILTERED_QUESTIONS';

//settings
export const GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL';
export const GET_PLANS = 'GET_PLANS';
export const VALIDATE_COUPON_CODE = 'VALIDATE_COUPON_CODE';
export const APPLY_CODE = 'APPLY_CODE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const CHANGE_EMAIL_TEMPLATE = 'CHANGE_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const CLOSE_INVITE_EMAIL_MODAL = 'CLOSE_INVITE_EMAIL_MODAL';
export const PREVIEW_INVITE_TEMPLATE = 'PREVIEW_INVITE_TEMPLATE';

//users
export const GET_USERS = 'GET_USERS';
export const GET_NEXT_USERS = 'GET_NEXT_USERS';
export const NO_USER_FOUND = 'NO_USER_FOUND';
export const RESET_USERS_LIST = 'RESET_USERS_LIST';
export const ADD_USERS = 'ADD_USERS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const GET_TENANTS = 'GET_TENANTS';
export const GET_NEXT_TENANTS = 'GET_NEXT_TENANTS';

export const GET_TENANTS_USER = 'GET_TENANTS_USER';
export const UPDATE_TENANT_STATUS = 'UPDATE_TENANT_STATUS';


//subscription plans
export const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS';
export const UPDATE_SUBSCRIPTION_PLANS = 'UPDATE_SUBSCRIPTION_PLANS';

// * coding question create
export const GET_CODING_QUESTION_LANGUAGES = 'GET_CODING_QUESTION_LANGUAGES';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const GET_CODING_QUESTION_RETURN_TYPES = 'GET_CODING_QUESTION_RETURN_TYPES';
export const CREATE_CODING_QUESTION = 'CREATE_CODING_QUESTION';
export const UPDATE_CODING_QUESTION = 'UPDATE_CODING_QUESTION';
export const CODING_QUESTION_DETAILS_BY_ID = 'CODING_QUESTION_DETAILS_BY_ID';
export const RESET_CODING_QUESTION = 'RESET_CODING_QUESTION';
export const QUESTION_TAGS = 'QUESTION_TAGS';
export const DEFAULT_CODING_QUESTION = 'DEFAULT_CODING_QUESTION';
export const LIST_CODING_QUESTION = 'LIST_CODING_QUESTION';
export const LIST_NEXT_CODING_QUESTION = 'LIST_NEXT_CODING_QUESTION';
export const CREATE_CODING_TEST = 'CREATE_CODING_TEST';
export const UPDATE_CODING_TEST = 'UPDATE_CODING_TEST';
export const GET_ONE_CODING_TEST = 'GET_ONE_CODING_TEST';
export const EDIT_CODE_QUESTION = 'EDIT_CODE_QUESTION';


//Layout 
export const DOMAIN_DETAIL = 'DOMAIN_DETAIL';