import React, { Fragment, useEffect, useState } from "react";
import './editor.scss';
import Switch from 'react-switch';
import useKeyPress from "./useKeypress";
import Select, { components } from "react-select";
import Editor from "@monaco-editor/react";
import { ThreeDots } from "../../Loader/loader";
import { useDispatch } from "react-redux";
import { compileCode, submitCode } from '../../Redux/ActionCreators/candidateTestAction';
import { returnErrors } from "../../Redux/ActionCreators/errorAction";
import { useTranslation } from 'react-i18next';


const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src="/images/select-arrow.svg" alt="" width="15px" />
        </components.DropdownIndicator>
    );
};

const Main = (props) => {
    const [code, setCode] = useState('');
    const [value, setValue] = useState(code || "");
    const [processing, setProcessing] = useState(null);
    const [compile, setCompile] = useState(false);
    const [language, setLanguage] = useState(props?.question?.languages?.map(el => [{ ...el, label: el.displayLanguage, value: el.codingLanguage }][0])[0]);
    const [check, setCheck] = useState(false);
    const [theme, setTheme] = useState("vs-light");
    const [active, setActive] = useState('');
    const [submit, setSubmit] = useState(false);
    const { t } = useTranslation();
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [output, setOutput] = useState(null);
    const [outputStatus, setOutputStatus] = useState(null);



    const enterPress = useKeyPress("Enter");
    const ctrlPress = useKeyPress("Control");
    const dispatch = useDispatch();

    console.log('qesdata', props.question)

    useEffect(() => {
        setCode(props.question?.defaultCode);
        onChangeCode("code", props.question?.defaultCode);
    }, [])

    useEffect(() => {
        setLanguage(props.question?.languages?.map(el => [{ ...el, label: el.displayLanguage, value: el.codingLanguage }][0])[0])
        setCode(props.question?.defaultCode);
        onChangeCode("code", props.question?.defaultCode);
    }, [props.question?.defaultCode])

    console.log(props.question?.languages)

    const handleChangeMode = async (e) => {
        console.log(e)
        await setCheck(e)
        await setTheme(e ? 'vs-dark' : 'vs-light')
    }

    const onSelectChange = (sl) => {
        console.log((t("selected Option...")), sl);
        setLanguage(sl);
        setValue(sl.defaultCode);
        onChangeCode("code", sl.defaultCode);
    };




    const handleEditorChange = (value) => {
        setValue(value);
        onChangeCode("code", value);
    };

    useEffect(() => {
        if (enterPress && ctrlPress) {
            console.log("enterPress", enterPress);
            console.log("ctrlPress", ctrlPress);
            handleCompile();
        }
    }, [ctrlPress, enterPress]);

    const onChangeCode = (action, data) => {
        switch (action) {
            case "code": {
                setCode(data);
                setValue(data)
                break;
            }
            default: {
                console.warn((t("case not handled!")), action, data);
            }
        }
    };

    const handleCompile = async (e) => {
        e.preventDefault();
        setProcessing(true);
        setCompile(true);
        setActive('run')

        const formData = {
            "token": props.inviteToken,
            "candidateTestId": props.question?.candidateTestId,
            "candidateTestQuestionId": props.question.candidateQuestionId,
            language: language?.value,
            sourceCode: code,
            inputs: props.question.inputs || [],
            output: ''
        };
        if (!language?.value) {
            returnErrors(dispatch, (t('Please select language')), 500);
        } else {

            const res = await dispatch(compileCode(formData, props.token));
            if (res?.type === 'RUN_CODE') {
                setProcessing(false);
                setOutputStatus(res?.payload);
                setOutput(res?.payload?.stdout);
                onChangeCode('code', code)
            }
        }
        setProcessing(false);
    };



    const codeSubmit = async () => {
        await setProcessing(true);
        if (!language?.value) {
            returnErrors(dispatch, (t('Please select language')), 500);
        } else {

            let obj = {
                "token": props.inviteToken,
                "candidateTestId": props.question?.candidateTestId,
                "candidateTestQuestionId": props.question.candidateQuestionId,
                "sourceCode": code,
                "language": language?.value,
                "inputs": props.question.inputs || [],
                "output": ''
            }
            const res = await dispatch(submitCode(obj, props.token));
            if (res?.type === 'SUBMIT_CODE') {
                setCode('');
                onChangeCode("code", '');
                await goNext();
                setLanguage(props.question?.languages?.map(el => [{ ...el, label: el.displayLanguage, value: el.codingLanguage }][0])[0])
                setCompile(false)
            }
        }
        await setProcessing(false);
    }

    const goNext = () => {
        props.getNext();
    }

    return (
        <div className="coding-container" style={{ background: "#fafafa", }}>


            <div className="d-flex flex-wrap mt-2" style={{ border: '1px solid #D7DADB' }}>
                <div className="col-md-5" style={{ padding: 15, borderRight: '2px solid #D7DADB' }} >
                    <h2 >
                        {props.question?.question}
                    </h2>
                    <p className="mt-3" dangerouslySetInnerHTML={{ __html: props.question?.description }} />
                </div>
                <div className="col-md-7">
                    {!submit ?
                        <Fragment>
                            <div className="lang-dd" >
                                <div class="d-flex  align-items-center">
                                    <Switch
                                        onChange={(e) => {
                                            handleChangeMode(e)
                                        }}
                                        width={30}
                                        height={16}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor="#02c573"
                                        checked={check}
                                    />&nbsp;&nbsp;
                                    <label className="col-form-label">{t('Dark Mode')}</label>&nbsp;&nbsp;

                                </div>
                                <div class="d-flex  align-items-center" style={{ width: '40%' }}>
                                    <label className="col-form-label">{t('Language')}</label>&nbsp;&nbsp;
                                    <div style={{ width: '70%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={`Filter By Category`}
                                            options={props.question?.languages?.map(el => [{ ...el, label: el.displayLanguage, value: el.codingLanguage }][0])}
                                            // styles={customStyles}
                                            value={language}
                                            onChange={(selectedOption) => onSelectChange(selectedOption)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <img src='/images/reset.svg' alt="" className="cursor-pointer" onClick={() => onChangeCode("code", '')} />
                                </div>
                            </div>
                            <div style={{ borderBottom: '2px solid #D7DADB' }}>

                            </div>
                            <div className="mt-2">
                                <Editor
                                    height="60vh"
                                    width={`100%`}
                                    language={language}
                                    value={value}
                                    theme={theme}
                                    defaultValue="// some comment"
                                    onChange={handleEditorChange}

                                    options={{
                                        autoIndent: 'full',
                                        contextmenu: true,
                                        fontFamily: 'monospace',
                                        lineHeight: 24,
                                        hideCursorInOverviewRuler: true,
                                        inlineSuggest: true,
                                        fontSize: "16px",
                                        formatOnType: true,
                                        autoClosingBrackets: true,
                                        minimap: { enabled: false },
                                        scrollbar: {
                                            horizontalSliderSize: 4,
                                            verticalSliderSize: 18,
                                        },
                                        selectOnLineNumbers: true,
                                        roundedSelection: false,
                                        readOnly: false,
                                        cursorStyle: 'line',
                                        automaticLayout: true,
                                    }}
                                />
                                <div className="run-code-view">
                                    <div className="run-header">
                                        <h3 className="cursor-pointer" onClick={(e) => handleCompile(e)}>{t('RUN CODE')}</h3>
                                        {/* <h3 onClick={async () => {
                                            await setCompile(true)
                                            await setActive('testcase')
                                        }}>TEST AGAINST CUSTOM INPUT</h3> */}
                                        <button className="submit-btn" disabled={processing} onClick={() => codeSubmit()}>{t('SUBMIT CODE')}</button>
                                    </div>
                                    {compile ?
                                        <div className="complie-window" style={{ minHeight: '200px' }}>
                                            {
                                                active === 'run' ?
                                                    <Fragment>
                                                        <div>
                                                            <h5>{processing ? <img src='/images/check-grey.svg' alt="" /> : <img src='/images/check-green.svg' alt="" />}&nbsp;&nbsp;{t('Compiling')}</h5>
                                                            {outputStatus?.executionStatus === 1 ?
                                                                <p style={{ color: 'green' }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: outputStatus?.stdout }} />
                                                                </p> :
                                                                (outputStatus?.executionStatus === 2 || outputStatus?.executionStatus === 3) ?
                                                                    <p style={{ color: 'red' }}>
                                                                        {outputStatus?.exception}
                                                                        <div dangerouslySetInnerHTML={{ __html: outputStatus?.stderr }} />
                                                                    </p>
                                                                    : null
                                                            }
                                                        </div>
                                                        {processing ? <div className="ms-3">
                                                            <ThreeDots color='#009199' />
                                                        </div> :
                                                            null
                                                        }
                                                    </Fragment> :
                                                    active === 'testcase' ?
                                                        <div className="d-flex flex-column">
                                                            <h2>{t('Add your inputs')}</h2>
                                                            <div className="mt-3" >
                                                                <div class="d-flex  align-items-center" style={{ width: '100%' }}>
                                                                    <label style={{ width: '20%' }} className="col-form-label">{t('Input 1(int)')}</label>&nbsp;
                                                                    <input type='text' className="form-control" onChange={(e) => setInput1(e.target.value)} />
                                                                </div>
                                                                <div class="d-flex  align-items-center mt-2" style={{ width: '100%' }}>
                                                                    <label style={{ width: '20%' }} className="col-form-label">{t('Input 2(str)')}</label>&nbsp;
                                                                    <input type='text' className="form-control" onChange={(e) => setInput2(e.target.value)} />
                                                                </div>
                                                                <div className="mt-3">
                                                                    <button className="run-btn" onClick={handleCompile} disabled={(input1 === '' || input2 === '')}>{t('RUN CODE')}</button>
                                                                </div>
                                                                <div class="d-flex  align-items-center mt-4" style={{ width: '100%' }}>
                                                                    <label style={{ width: '20%' }} className="col-form-label">{t('Output(Int)')}</label>&nbsp;
                                                                    <input type='text' className="form-control" onChange={(e) => setOutput(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            {processing ? <div className="ms-3">
                                                                <ThreeDots color='#009199' />
                                                            </div> :
                                                                null
                                                            }
                                                        </div> :
                                                        null


                                            }

                                        </div> :
                                        null
                                    }
                                </div>
                            </div>
                        </Fragment> :
                        <div className="text-center" style={{ padding: 150 }}>
                            <img src='/images/green-check.svg' alt="" />
                            <h6>{t('Your code was submitted successfully. You are now being redirected to the next question')}</h6>
                            <button className="output-btn" onClick={() => (!props.isNextTest && !props.isTestCompleted) ? goNext() : (props.isTestCompleted) ? props.setActive('feedback') : props.isNextTest ? props.getSummary() : null}>{t('View Next Question')}</button>
                        </div>
                    }


                </div>
            </div>



            {/* <Footer /> */}
        </div>
    );
};


export default Main;