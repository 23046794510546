import { returnErrors } from './errorAction';
import {
    GET_GAPPEO_STATS, GET_SEARCH_RESULTS, GET_NEXT_SEARCH_RESULTS, NO_FOUND, RESET_SEARCH_RESULT, RESET_TEST_LOADING, RESET_TEST_DETAIL, GET_LANGUAGES, HANDLE_LANGUAGE, GET_TEST_LIST, GET_NON_CORE_TEST, SELECT_ONE, CONFIRM_REPLACE, REMOVE_FROM_SELECTED, VALIDATE_NAME, RESET_SELECTED_TEST, REORDER_TEST, CREATE_CUSTOM_TEST, UPDATE_TEST,
    UPDATE_TEST_NAME, GET_TEST_DETAILS,
    SET_ACTIVE_TABS, RESET_QUESTIONS, GET_ADDITIONAL_QUESTION, GET_NEXT_ADDITIONAL_QUESTION, NO_QUESTION, SAVE_SELECTED_QUESTION, SELECT_ONE_QUESTION, GET_TEST_BY_ID, REMOVE_FROM_SELECTED_QUESTION,
    GET_QUESTION_TYPES, GET_QUESTION_LEVELS, RESET_OPTIONS, RESET_QUESTION, SET_QUES_TITLE, SET_OPTIONS, SET_RIGHT_ANSWER, SET_ERRORS, SAVED_QUESTION, EDITED_QUESTION, DELETE_QUESTION, SET_QUESTION_DETAILS,
    GET_TENENT_USERS, GET_TEMPLATE, CLOSE_INVITE_MODAL, CHANGE_TEMPLATE, PREVIEW_TEMPLATE, UPDATE_TEMPLATE, SEND_INVITES, GET_SKILLS_TYPES, GET_SKILLS_TOPIC, GET_SKILLS_QUESTIONS, GET_CODING_QUESTION_LANGUAGES, UPDATE_LANGUAGE, GET_CODING_QUESTION_RETURN_TYPES, CREATE_CODING_QUESTION, UPDATE_CODING_QUESTION, RESET_CODING_QUESTION, QUESTION_TAGS, DEFAULT_CODING_QUESTION, LIST_CODING_QUESTION, LIST_NEXT_CODING_QUESTION, CODING_QUESTION_DETAILS_BY_ID,
    SELECT_CODING_QUES, REMOVE_FROM_SELECT, SAVE_SELECT_CODING_QUESTION, CREATE_CODING_TEST, UPDATE_CODING_TEST, EDIT_CODE_QUESTION, GET_ONE_CODING_TEST
} from '../Types/types';
import { getApiCall, getV2ApiCall, postApiCall } from '../../utils/request';


export const getGappeoStats = () => async (dispatch) => {
    try {
        const response = await getApiCall(`user/get-stats`);
        if (response.status === 200) {
            dispatch({ type: GET_GAPPEO_STATS, payload: response.data.stats })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getSearchResults = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getV2ApiCall(`testlibrary/search-test-library?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        if (response.status === 200) {
            dispatch({ type: GET_SEARCH_RESULTS, payload: response.data })
        } else {
            dispatch({ type: NO_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getNextSearchResults = (query, page, rows) => async (dispatch) => {
    try {
        const response = await getV2ApiCall(`testlibrary/search-test-library?SearchText=${encodeURIComponent(query)}&PageNumber=${page}&DisplayRows=${rows}`);
        dispatch({ type: GET_NEXT_SEARCH_RESULTS, payload: response.data })
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const resetSearchResult = () => async (dispatch) => {
    return dispatch({ type: RESET_SEARCH_RESULT })
}

export const resetTestLoading = () => async (dispatch) => {
    return dispatch({ type: RESET_TEST_LOADING })
}

export const resetTestDetails = () => async (dispatch) => {
    return dispatch({ type: RESET_TEST_DETAIL })
}

export const getLanguages = () => async (dispatch) => {
    try {
        const response = await getApiCall(`language/list`);
        console.log(response.data)
        if (response.status === 200) {
            dispatch({ type: GET_LANGUAGES, payload: response.data.languages })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const handleLanguage = (val) => async (dispatch) => {
    return dispatch({ type: HANDLE_LANGUAGE, payload: val })
}

export const getTestList = (level, referenceType, referenceId, search, page, rows, testType) => async (dispatch) => {
    try {
        const response = await getV2ApiCall(`test/list-test?TestLevel=${level}&SearchReferenceType=${referenceType}&ReferenceId=${referenceId}&SearchText=${search}&PageNumber=${page}&DisplayRows=${rows}&TestType=${testType}`);
        const data = response.data.languages
        console.log(data)
        if (response.status === 200) {
            return dispatch({ type: GET_TEST_LIST, payload: testType === 1 ? { data, testType } : response.data.testList })
        } else if (response.status === 204) {
            return dispatch({ type: GET_TEST_LIST, payload: [] })
        } else {
            dispatch({ type: NO_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getNoCoreTestList = (level, referenceType, referenceId, search, page, rows) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/list-test?TestLevel=${level}&SearchReferenceType=${referenceType}&ReferenceId=${referenceId}&SearchText=${search}&PageNumber=${page}&DisplayRows=${rows}`);
        if (response.status === 200) {
            dispatch({ type: GET_NON_CORE_TEST, payload: response.data.testList })
        } else {
            dispatch({ type: NO_FOUND, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const checkOne = (id, checked, data, type) => async (dispatch) => {
    return dispatch({ type: SELECT_ONE, payload: { id, checked, data, type } })
}

export const confirmReplace = (data, newData) => async (dispatch) => {
    return dispatch({ type: CONFIRM_REPLACE, payload: { data, newData } })
}


export const removeFromSelected = (data) => async (dispatch) => {

    return dispatch({ type: REMOVE_FROM_SELECTED, payload: data })
}

export const resetSelectedTest = () => async (dispatch) => {
    return dispatch({ type: RESET_SELECTED_TEST })
}

export const checkValidTest = (name) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/is-valid-test-name?TestName=${name}`);
        if (response.status === 200) {
            return dispatch({ type: VALIDATE_NAME, payload: response.data })
        }
    }
    catch (error) {
        // returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const reorderTest = (data) => async (dispatch) => {
    return dispatch({ type: REORDER_TEST, payload: data })
}

export const createCustomTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/create-custom-test`, obj);
        if (response.status === 200) {
            return dispatch({ type: CREATE_CUSTOM_TEST, payload: response.data.testId })
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const updateTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/update-custom-test`, obj);
        if (response.status === 200) {
            dispatch(getSelectedTests(obj.testId))
            return dispatch({ type: UPDATE_TEST, payload: response.data.testId })
        } else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}



//Add question page


export const updateTestName = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/update-assessment`, obj);
        if (response.status === 200) {
            return dispatch({ type: UPDATE_TEST_NAME, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getSelectedTests = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/get-test?TestId=${id}`);
        if (response.status === 200) {
            return dispatch({ type: GET_TEST_DETAILS, payload: response.data.test })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const setActiveTabs = (tab) => async (dispatch) => {
    return dispatch({ type: SET_ACTIVE_TABS, payload: tab })
}

export const resetQuestions = () => async (dispatch) => {
    return dispatch({ type: RESET_QUESTIONS })
}



export const getv2AdditionalQuestion = (query, skillId, skillTopicId, questionLevelId, questionTypeId, page, rows) => async (dispatch) => {
    try {
        let apiUrl = `question/list-question?SearchText=${query}&SkillId=${skillId}&SkillTopicId=${skillTopicId}&QuestionLevelId=${questionLevelId}&QuestionTypeId=${questionTypeId}&PageNumber=${page}&DisplayRows=${rows}`;
        let response = await getV2ApiCall(apiUrl);
        if (response.status === 200) {
            dispatch({ type: GET_ADDITIONAL_QUESTION, payload: response.data })
        } else if (response.status === 204) {
            dispatch({ type: NO_QUESTION, payload: [] })
        }
        else {
            dispatch({ type: NO_QUESTION, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getNextv2AdditionalQuestion = (query, skillId, skillTopicId, questionLevelId, questionTypeId, page, rows) => async (dispatch) => {
    try {
        let apiUrl = `question/list-question?SearchText=${query}&SkillId=${skillId}&SkillTopicId=${skillTopicId}&QuestionLevelId=${questionLevelId}&QuestionTypeId=${questionTypeId}&PageNumber=${page}&DisplayRows=${rows}`;
        let response = await getV2ApiCall(apiUrl);
        if (response.status === 200) {
            dispatch({ type: GET_NEXT_ADDITIONAL_QUESTION, payload: response.data })
        } else if (response.status === 204) {
            dispatch({ type: NO_QUESTION, payload: [] })
        }
        else {
            dispatch({ type: NO_QUESTION, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}


export const getAdditionalQuestion = (IsPreQualifierQuestions, query, page, rows, skillId, skillTopicId) => async (dispatch) => {
    try {
        let apiUrl = 'question/list-question';
        let response
        if (skillId && skillTopicId) {
            apiUrl += `?SkillId=${skillId}&SkillTopicId=${skillTopicId}&SearchText=${query}&IsPreQualifierQuestions=1&PageNumber=1&DisplayRows=20`;
            response = await getApiCall(apiUrl);

        } else {
            response = await getApiCall(`question/list-question?IsPreQualifierQuestions=${IsPreQualifierQuestions}&SearchText=${query}&PageNumber=${page}&DisplayRows=${rows}`);
        }
        if (response.status === 200) {
            dispatch({ type: GET_ADDITIONAL_QUESTION, payload: response.data })
        } else if (response.status === 204) {
            dispatch({ type: NO_QUESTION, payload: [] })
        }
        else {
            dispatch({ type: NO_QUESTION, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getNextAdditionalQuestion = (IsPreQualifierQuestions, query, page, rows, skillId, skillTopicId) => async (dispatch) => {
    try {
        let apiUrl = 'question/list-question';
        let response
        if (skillId && skillTopicId) {
            apiUrl += `?SkillId=${skillId}&SkillTopicId=${skillTopicId}&SearchText=${query}&IsPreQualifierQuestions=1&PageNumber=1&DisplayRows=20`;
            response = await getApiCall(apiUrl);

        } else {
            response = await getApiCall(`question/list-question?IsPreQualifierQuestions=${IsPreQualifierQuestions}&SearchText=${query}&PageNumber=${page}&DisplayRows=${rows}`);
        }

        if (response.status === 200) {
            dispatch({ type: GET_NEXT_ADDITIONAL_QUESTION, payload: response.data })
        } else {
            dispatch({ type: NO_QUESTION, })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const saveSelectQuestion = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_SELECTED_QUESTION, payload: data
    })
}

export const selectOneQuestion = (id, checked, data) => async (dispatch) => {
    dispatch({
        type: SELECT_ONE_QUESTION, payload: { id, checked, data }
    })
}

export const getTestById = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/get-test?TestId=${id}`);
        console.log(response.data)
        if (response.status === 200) {
            return dispatch({ type: GET_TEST_BY_ID, payload: response.data.myTest })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const removeQuestion = (data) => async (dispatch) => {
    dispatch({
        type: REMOVE_FROM_SELECTED_QUESTION, payload: data
    })
}



export const getQuestionTypes = () => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-question-type`);
        if (response.status === 200) {
            return dispatch({ type: GET_QUESTION_TYPES, payload: response.data.questionTypes })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}





export const getQuestionLevels = () => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-question-level`);
        if (response.status === 200) {
            return dispatch({ type: GET_QUESTION_LEVELS, payload: response.data.questionLevels })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const resetQuestion = () => async (dispatch) => {
    dispatch({
        type: RESET_QUESTION
    })
}

export const resetOptions = () => async (dispatch) => {
    dispatch({
        type: RESET_OPTIONS
    })
}

export const setTitle = (val) => async (dispatch) => {
    dispatch({
        type: SET_QUES_TITLE,
        payload: val
    })
}

export const setOptionValues = (key, idx, value) => async (dispatch) => {
    dispatch({
        type: SET_OPTIONS, payload: { key, idx, value }
    })
}

export const setRightAnswer = (key, idx, checked, quesType) => async (dispatch) => {
    dispatch({
        type: SET_RIGHT_ANSWER, payload: { key, idx, checked, quesType }
    })
}

export const setErrors = (id) => async (dispatch) => {
    return dispatch({ type: SET_ERRORS, payload: id })
}

export const savedQuestion = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`question/add-question`, obj);
        if (response.status === 200) {
            return dispatch({ type: SAVED_QUESTION, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const editedQuestion = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`question/edit-question`, obj);
        if (response.status === 200) {
            return dispatch({ type: EDITED_QUESTION, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const deleteQuestion = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`question/delete-question`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Question deleted', 200)
            return dispatch({ type: DELETE_QUESTION, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const setQuestionValues = (detail) => async (dispatch) => {
    return dispatch({ type: SET_QUESTION_DETAILS, payload: detail })
}


//Invite and preview page

export const getTenantUsers = () => async (dispatch) => {
    try {
        const response = await getApiCall(`user/list-tenant-user`);
        if (response.status === 200) {
            dispatch({ type: GET_TENENT_USERS, payload: response.data.users })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const previewTemplate = () => async (dispatch) => {
    return dispatch({ type: PREVIEW_TEMPLATE })
}

export const getInvitationTemplates = (testId, type, edit) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/get-email-template?TestID=${testId}&EmailTemplateType=${type}`);
        const data = response.data;
        if (response.status === 200) {
            dispatch({ type: GET_TEMPLATE, payload: { type, edit, data } })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const changeTemplate = (content, type) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_TEMPLATE, payload: { content, type } })

    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const updateTemplate = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/update-email-template`, obj);
        // const data = response.data;
        if (response.status === 200) {
            return dispatch({ type: UPDATE_TEMPLATE, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}




export const closeInviteModal = () => async (dispatch) => {
    dispatch({
        type: CLOSE_INVITE_MODAL
    })
}

// Action to fetch the email template
export const getEmailTemplate = (type, edit) => async (dispatch) => {
    let obj = {
        "emailTemplateType": type
    }
    try {
        const response = await postApiCall(`user/get-email-template`, obj);
        const data = response.data;
        if (response.status === 200) {
            dispatch({ type: GET_TEMPLATE, payload: { type, edit, data } })
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
};


// Action to update the email template
export const updateEmailTemplate = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/update-email-template`, obj);
        // const data = response.data;
        if (response.status === 200) {
            return dispatch({ type: UPDATE_TEMPLATE, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}


//invite and preview page
export const sendInvites = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/send-test-invite`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Invitation sent', response?.status);
            return dispatch({ type: SEND_INVITES });
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const sendInviteswithFile = (obj) => async (dispatch) => {
    const data = new FormData();
    data.append('testId', obj.testId);
    data.append('file', obj.file)
    try {
        const response = await postApiCall(`test/upload-test-invite-file?validTill=${obj?.validTill}&testURL=${obj?.testURL}`, data);
        if (response.status === 200) {
            returnErrors(dispatch, 'Invitation sent', response?.status);
            return dispatch({ type: SEND_INVITES });
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

// Get skills 
export const getSkillsList = (categoryId) => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-skill?CategoryId=${categoryId}`);
        if (response.status === 200) {
            // console.log('response.data.skills ', response?.data)
            dispatch({ type: GET_SKILLS_TYPES, payload: response?.data })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Get skills topic 
export const getSkillsTopic = (skillId) => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-skill-topic?SkillId=${skillId}`);
        if (response.status === 200) {
            // console.log('response.data topic ', response?.data)
            return dispatch({ type: GET_SKILLS_TOPIC, payload: response?.data })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// Get Question based on skills and topic
export const getSkillsQuestion = (skillID, skillTopic, query) => async (dispatch) => {
    let apiUrl = `question/list-question`;

    // Check if skillID and skillTopic are not null
    if (skillID && skillTopic) {
        apiUrl += `?SkillId=${skillID}&SkillTopicId=${skillTopic}&IsPreQualifierQuestions=1&PageNumber=1&DisplayRows=20`;
        // If query is not null or empty, append it to the apiUrl
        if (query) {
            apiUrl += `&SearchText=${query}`;
        }
    } else {
        // If either skillID or skillTopic is null, check if query is present
        if (query) {
            // Only append query if it exists
            apiUrl += `?SearchText=${query}&IsPreQualifierQuestions=1&PageNumber=1&DisplayRows=20`;
        }
        else {
            apiUrl += `?IsPreQualifierQuestions=1&PageNumber=1&DisplayRows=20`;
        }
    }


    try {
        const response = await getApiCall(apiUrl);
        if (response.status === 200) {
            // console.log('GET_SKILLS_QUESTIONS.data  ', response.data)
            dispatch({ type: GET_SKILLS_QUESTIONS, payload: response.data })
        } else if (response.status === 204) {
            // console.log('GET_SKILLS_QUESTIONS.data 204  ', response.data)
            dispatch({ type: NO_QUESTION, payload: [] })
        } else {
            dispatch({ type: NO_QUESTION })
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
};


// ----------------------------------------------------------------CREATE CODING QUESTION API END-PONT METHODS----------------------------------------------------------------


export const getDefaultCodingQuestions = (DifficultyLevelId, CodingLanguageId) => async (cb) => {
    try {
        const response = await getApiCall(`codingtest/list-question?TestLevelId=${DifficultyLevelId}&CodingLanguageId=${CodingLanguageId}`);
        console.log(response.status)
        if (response?.status === 200) {
            cb({ type: DEFAULT_CODING_QUESTION, payload: response?.data })
        } else {
            cb({ type: DEFAULT_CODING_QUESTION, payload: { questions: [], totalCount: 0 } })
        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong ', error?.response?.status);
    }
};

export const resetCodingQuestions = () => async (dispatch) => {
    return dispatch({ type: RESET_CODING_QUESTION })
}

export const getCodingQuestions = (PageNumber, DisplayRows, DifficultyLevelId, CodingLanguageId, SearchText, ExcludeQuestionIds) => async (cb) => {
    try {
        const response = await getApiCall(`codingquestion/list-question?PageNumber=${PageNumber}&DisplayRows=${DisplayRows}&DifficultyLevelId=${DifficultyLevelId}&CodingLanguageId=${CodingLanguageId}&SearchText=${SearchText}&ExcludeQuestionIds=${encodeURIComponent((ExcludeQuestionIds))}`);
        console.log(response.status)
        if (response?.status === 200) {
            cb({ type: LIST_CODING_QUESTION, payload: response?.data })
        } else {
            cb({ type: LIST_CODING_QUESTION, payload: { questions: [], totalCount: 0 } })
        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong', error?.response?.status);
    }
};

export const getNextCodingQuestions = (PageNumber, DisplayRows, DifficultyLevelId, CodingLanguageId, SearchText, ExcludeQuestionIds) => async (cb) => {
    try {
        const response = await getApiCall(`codingquestion/list-question?PageNumber=${PageNumber}&DisplayRows=${DisplayRows}&DifficultyLevelId=${DifficultyLevelId}&CodingLanguageId=${CodingLanguageId}&SearchText=${SearchText}&ExcludeQuestionIds=${encodeURIComponent(ExcludeQuestionIds)}`);
        if (response?.status === 200) {
            cb({ type: LIST_NEXT_CODING_QUESTION, payload: response?.data })
        } else {
            cb({ type: LIST_NEXT_CODING_QUESTION, payload: [] })
        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong', error?.response?.status);
    }
};

export const getCodingQuestionLanguages = () => async (cb) => {
    try {
        const response = await getApiCall(`codingquestion/list-language`);
        if (response?.status === 200) {
            cb({ type: GET_CODING_QUESTION_LANGUAGES, payload: response?.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong', error?.response?.status);
    }
};

export const updateLanguageText = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`codingquestion/update-language`, obj);
        if (response?.status === 200) {
            returnErrors(dispatch, 'Language code updated', response.status);
            return dispatch({ type: UPDATE_LANGUAGE, payload: response?.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong while getting Languages', error?.response?.status);
    }
};

export const getCodingQuestionReturnTypes = () => async (cb) => {
    try {
        const response = await getApiCall(`codingquestion/list-return-type`);
        if (response?.status === 200) {
            cb({ type: GET_CODING_QUESTION_RETURN_TYPES, payload: response?.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong while getting ReturnTypes', error?.response?.status);
    }
};

export const getQuestionTags = () => async (dispatch) => {
    try {
        const response = await getApiCall(`codingquestion/list-tag`);
        console.log(response.data.tags)
        if (response?.status === 200) {
            dispatch({ type: QUESTION_TAGS, payload: response?.data.tags })
        } else {
            dispatch({ type: QUESTION_TAGS, payload: [] })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
};

export const postCreateCodingQuestion = (request) => async (cb) => {
    try {
        const response = await postApiCall(`codingquestion/create`, request);
        const data = response.data
        console.log(data, request.addFromList)
        if (response.status === 200) {
            if (request.addFromList) {
                const res = await getApiCall(`codingquestion/get?id=${data.question.id}`);
                console.log('602', res.data)
                const data1 = res.data
                return cb({ type: CREATE_CODING_QUESTION, payload: { data1, request } })
            } else {
                return cb({ type: CREATE_CODING_QUESTION, payload: { data, request } })
            }
        } else {

        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong while creating coding question', error?.response?.status);
    }
};




export const postUpdateCodingQuestion = (request_body) => async (cb) => {
    try {
        const response = await postApiCall(`codingquestion/update`, request_body);
        if (response?.status === 200) {
            return cb({ type: UPDATE_CODING_QUESTION, payload: response?.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong', error?.response?.status);
    }
};

export const getCodingQuestionByID = (coding_question_id) => async (cb) => {
    try {
        const response = await getApiCall(`codingquestion/get?id=${coding_question_id}`);
        if (response?.status === 200) {
            cb({ type: CODING_QUESTION_DETAILS_BY_ID, payload: response?.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(cb, 'Something went wrong', error?.response?.status);
    }
};

export const deleteCodingQuestion = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`codingquestion/delete`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Question deleted', 200)
            return dispatch({ type: DELETE_QUESTION, payload: obj })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong while deleting coding question', error?.response?.status);
    }
}

export const selectCodingQues = (id, checked, data) => async (dispatch) => {

    return dispatch({ type: SELECT_CODING_QUES, payload: { id, checked, data } })
}

export const removeFromSelect = (data) => async (dispatch) => {

    return dispatch({ type: REMOVE_FROM_SELECT, payload: data })
}

export const saveSelectCodingQuestion = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_SELECT_CODING_QUESTION, payload: data
    })
}

export const getCodingTestByID = (id) => async (dispatch) => {
    try {
        const res = await getApiCall(`codingtest/get?id=${id}`);
        return dispatch({ type: GET_ONE_CODING_TEST, payload: res.data.test });
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong while creating coding question', error?.response?.status);
    }
};


export const createCodingTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`codingtest/create`, obj);
        console.log(response.data)
        if (response.status === 200) {
            returnErrors(dispatch, 'Coding test created', 200)
            const res = await getApiCall(`codingtest/get?id=${response.data.testId}`);

            return dispatch({ type: CREATE_CODING_TEST, payload: res.data.test });

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong while deleting coding question', error?.response?.status);
    }
}

export const updateCodingTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`codingtest/update`, obj);
        console.log(response.data)
        if (response.status === 200) {
            returnErrors(dispatch, 'Coding test updated', 200)
            const res = await getApiCall(`codingtest/get?id=${obj.id}`);

            return dispatch({ type: UPDATE_CODING_TEST, payload: res.data.test });

        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong while deleting coding question', error?.response?.status);
    }
}

export const editedCodingQuestion = (data) => async (dispatch) => {
    dispatch({
        type: EDIT_CODE_QUESTION, payload: data
    })
}
