import { getApiCall } from "../../utils/request";
import { returnErrors } from "./errorAction";
import { DOMAIN_DETAIL } from '../Types/types';

export const getTanentDomain = (domain) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/domain-detail?domain=${domain}`);
        if (response?.status === 200) {
            dispatch({ type: DOMAIN_DETAIL, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        // dispatch({ type: DOMAIN_DETAIL, payload: response.data });
        // returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}